@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 2px; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 2px; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 2px;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  min-width: initial; }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-up-9 {
  flex-wrap: wrap; }
  .small-up-9 > .column, .small-up-9 > .columns {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }

.small-up-10 {
  flex-wrap: wrap; }
  .small-up-10 > .column, .small-up-10 > .columns {
    flex: 0 0 10%;
    max-width: 10%; }

.small-up-11 {
  flex-wrap: wrap; }
  .small-up-11 > .column, .small-up-11 > .columns {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }

.small-up-12 {
  flex-wrap: wrap; }
  .small-up-12 > .column, .small-up-12 > .columns {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; }
  .medium-up-9 {
    flex-wrap: wrap; }
    .medium-up-9 > .column, .medium-up-9 > .columns {
      flex: 0 0 11.11111%;
      max-width: 11.11111%; }
  .medium-up-10 {
    flex-wrap: wrap; }
    .medium-up-10 > .column, .medium-up-10 > .columns {
      flex: 0 0 10%;
      max-width: 10%; }
  .medium-up-11 {
    flex-wrap: wrap; }
    .medium-up-11 > .column, .medium-up-11 > .columns {
      flex: 0 0 9.09091%;
      max-width: 9.09091%; }
  .medium-up-12 {
    flex-wrap: wrap; }
    .medium-up-12 > .column, .medium-up-12 > .columns {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; }
  .large-up-9 {
    flex-wrap: wrap; }
    .large-up-9 > .column, .large-up-9 > .columns {
      flex: 0 0 11.11111%;
      max-width: 11.11111%; }
  .large-up-10 {
    flex-wrap: wrap; }
    .large-up-10 > .column, .large-up-10 > .columns {
      flex: 0 0 10%;
      max-width: 10%; }
  .large-up-11 {
    flex-wrap: wrap; }
    .large-up-11 > .column, .large-up-11 > .columns {
      flex: 0 0 9.09091%;
      max-width: 9.09091%; }
  .large-up-12 {
    flex-wrap: wrap; }
    .large-up-12 > .column, .large-up-12 > .columns {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 64em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 1.875rem; }
  .column-block > :last-child {
    margin-bottom: 0; }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
#map #contact-map-box h4,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }


p,
#map #contact-map-box h4 {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: 500;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #25aae1;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1b94c6; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: 500; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p, blockquote #map #contact-map-box h4, #map #contact-map-box blockquote h4 {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #000000;
  color: #0a0a0a;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 400;
  color: #000000; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000000;
  border-radius: 2px; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat, #map #contact-map-box h4 + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  
  p,
  #map #contact-map-box h4,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 1em 1rem 0;
  padding: 0.6em 1.5em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #25aae1;
  color: #fff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #1b92c4;
    color: #fff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 1rem; }
  .button.large {
    font-size: 1.75rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #1779ba;
    color: #fff; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #fff; }
  .button.secondary {
    background-color: #767676;
    color: #000; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #000; }
  .button.success {
    background-color: #3adb76;
    color: #000; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #000; }
  .button.warning {
    background-color: #ffae00;
    color: #000; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #000; }
  .button.alert {
    background-color: #cc4b37;
    color: #000; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #000; }
  .button.hollow {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #1779ba;
      color: #fff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #fff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #000; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #000; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #000; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #000; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #FFF transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1.5em; }
  .button.dropdown.hollow::after {
    border-top-color: #25aae1; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 2px;
  background-color: #FFF;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  color: #000000;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #FFF;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: default; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 2px; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.8;
  color: #000000; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #333; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 2px 0 0 2px; }
  .input-group > :last-child > * {
    border-radius: 0 2px 2px 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #000000;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: #fefefe; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 2px;
  background-color: #fafafa;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #000000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2851, 51, 51%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #FFF;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: default; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #ec5840;
  background-color: #fdeeec; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #ec5840; }

.is-invalid-label {
  color: #ec5840; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #ec5840; }
  .form-error.is-visible {
    display: block; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' '; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 1.5rem; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 1rem; }
  .button-group.large .button {
    font-size: 1.75rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:last-child {
      width: 100%; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
      display: inline-block;
      width: calc(33.33333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
      display: inline-block;
      width: calc(16.66667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
        margin-right: -6px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #fff; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #fff; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #000; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #000; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #000; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #000; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #000; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #000; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #000; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #000; }
  .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      width: auto;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #000000; }
  .close-button.small {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.simple li {
    margin-left: 0;
    margin-right: 1rem; }
  .menu.simple.align-right li {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #1779ba;
    color: #FFF; }
  .menu.menu-bordered li {
    border: 1px solid #e6e6e6; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #e6e6e6; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block;
    vertical-align: top; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #FFF;
    box-shadow: 0 7px 0 #FFF, 0 14px 0 #FFF;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #000000;
    box-shadow: 0 7px 0 #000000, 0 14px 0 #000000;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #FFF;
  transition: transform 0.15s linear; }
  .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }

.drilldown-submenu-cover-previous {
  min-height: 100%; }

.is-drilldown-submenu-parent > a {
  position: relative; }
  .is-drilldown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem; }

.js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #cacaca;
  border-radius: 2px;
  background-color: #fefefe;
  font-size: 16rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #25aae1 transparent transparent;
  right: 5px;
  margin-top: -3px; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #25aae1 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #25aae1; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #25aae1 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #25aae1 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #25aae1; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #25aae1 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #25aae1 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #25aae1; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #FFF; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #25aae1 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #25aae1; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-overlap {
    z-index: 10; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 10; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  transform: translateX(-250px);
  overflow-y: auto; }
  .position-left.is-open ~ .off-canvas-content {
    transform: translateX(250px); }
  .position-left.is-transition-push::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-left.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-right {
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transform: translateX(250px);
  overflow-y: auto; }
  .position-right.is-open ~ .off-canvas-content {
    transform: translateX(-250px); }
  .position-right.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-right.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto; }
  .position-top.is-open ~ .off-canvas-content {
    transform: translateY(250px); }
  .position-top.is-transition-push::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-top.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto; }
  .position-bottom.is-open ~ .off-canvas-content {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.off-canvas-content {
  transition: transform 0.5s ease;
  backface-visibility: hidden; }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFF; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #FFF; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(0, 0, 0, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' '; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 2px;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 2px;
    color: #000000; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #25aae1;
    color: foreground(#25aae1);
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #000000; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 2px;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #1779ba; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #25aae1;
  border-radius: 2px; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #FFF;
  white-space: nowrap;
  border-radius: 2px; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 2px;
  background-color: #25aae1;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #1b92c4; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 2px;
  background-color: #FFF;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal .reveal {
      right: auto;
      left: auto;
      margin: 0 auto; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #FFF;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 2px;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 2px;
    background: #FFF;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #25aae1; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 2px; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #f2f2f2;
    background-color: #FFF; }
  caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: 500; }
  thead {
    background: #f9f9f9;
    color: #0a0a0a; }
  tfoot {
    background: #f2f2f2;
    color: #0a0a0a; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: 500;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f2f2f2; }
  table.unstriped tbody {
    background-color: #FFF; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f2f2f2;
      background-color: #FFF; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f4f4f4; }

table.hover tfoot tr:hover {
  background-color: #ededed; }

table.hover tbody tr:hover {
  background-color: #fafafa; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #FFF;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' '; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #1779ba; }
  .tabs.primary > li > a {
    color: #FFF; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #1673b1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #1779ba; }
    .tabs-title > a:hover {
      background: #FFF;
      color: #1468a0; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #1779ba; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #FFF;
  color: foreground(#FFF, #25aae1);
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel[aria-hidden="false"] {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #FFF;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(37, 170, 225, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #000000;
  color: #FFF; }
  .title-bar::before, .title-bar::after {
    display: table;
    content: ' '; }
  .title-bar::after {
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: 500;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 2px;
  background-color: #000000;
  font-size: 80%;
  color: #FFF; }
  .tooltip::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #000000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #000000 transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000000;
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000000 transparent transparent;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }

.top-bar {
  padding: 0; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: transparent; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .quotes .attribution::before, .labsCallout a::before, .clearfix::after, .quotes .attribution::after, .labsCallout a::after {
  display: table;
  content: ' '; }

.clearfix::after, .quotes .attribution::after, .labsCallout a::after {
  clear: both; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.masthead {
  display: block;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 53px;
  z-index: 100;
  overflow: hidden;
  -webkit-transition: background-color .4s .4s, height .3s ease-out;
  -moz-transition: background-color .4s .4s, height .3s ease-out;
  -ms-transition: background-color .4s .4s, height .3s ease-out;
  transition: background-color .4s .4s, height .3s ease-out; }
  body.admin-bar .masthead {
    top: 32px; }
  .masthead.fixed {
    background-color: rgba(0, 0, 0, 0.9) !important; }
    .masthead.fixed .top-bar ul li.active a:after {
      opacity: 1; }
    .masthead.fixed .top-bar ul li a:not(#brand):hover {
      background-color: rgba(0, 0, 0, 0.4) !important; }
  @media print, screen and (min-width: 40em) {
    .backdropfilter .masthead {
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px); }
      .backdropfilter .masthead.fixed {
        background-color: rgba(0, 0, 0, 0.7) !important; } }
  .masthead .top-bar h1 {
    display: inline-block;
    margin: 0;
    font-size: 16px; }
  .masthead .top-bar a#brand {
    display: inline-block;
    padding: 0;
    width: 190px;
    float: left;
    height: 53px; }
    .masthead .top-bar a#brand .letter {
      float: left;
      height: 34px;
      background: url(/images/logoGap.png) left top no-repeat;
      position: relative;
      top: 10px;
      display: block;
      opacity: 1; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .masthead .top-bar a#brand .letter {
          background: url(/images/logoGap@2x.png) left top no-repeat;
          background-size: 188px 34px; } }
      .masthead .top-bar a#brand .letter.letter-b {
        width: 23px; }
      .masthead .top-bar a#brand .letter.letter-r {
        background-position: -23px 0;
        width: 15px; }
      .masthead .top-bar a#brand .letter.letter-a {
        background-position: -39px 0;
        width: 22px;
        margin-left: -2px; }
      .masthead .top-bar a#brand .letter.letter-n {
        background-position: -61px 0;
        width: 24px; }
      .masthead .top-bar a#brand .letter.letter-d {
        background-position: -85px 0;
        width: 23px; }
      .masthead .top-bar a#brand .letter.letter-h {
        background-position: -108px 0;
        width: 23px; }
      .masthead .top-bar a#brand .letter.letter-oo {
        background-position: -131px 0;
        width: 47px; }
      .masthead .top-bar a#brand .letter.letter-t {
        background-position: -178px 0;
        width: 12px; }
  .masthead .top-bar ul.menu li {
    background: none; }
    .masthead .top-bar ul.menu li a {
      color: #cccccc;
      position: relative;
      text-transform: uppercase;
      line-height: 53px;
      padding: 0 16px;
      font-size: 14px; }
    .masthead .top-bar ul.menu li a:not(#brand):hover {
      background-color: rgba(0, 0, 0, 0.2) !important;
      color: #FFF; }
    .masthead .top-bar ul.menu li.active a {
      color: white;
      background-color: transparent; }
      @media screen and (min-width: 50.0625em) {
        .masthead .top-bar ul.menu li.active a:after {
          content: '';
          position: absolute;
          left: 16px;
          right: 16px;
          top: 0px;
          background-color: #25aae1;
          height: 3px;
          display: block;
          opacity: 0.7;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; } }
      .masthead .top-bar ul.menu li.active a:hover:after {
        left: 0;
        right: 0; }
  .masthead .top-bar-right, .masthead .top-bar-left {
    width: auto; }
  @media screen and (max-width: 50em) {
    .masthead.open {
      bottom: 0;
      height: auto;
      background-color: rgba(0, 0, 0, 0.95); }
    .masthead .top-bar-right {
      float: none;
      margin: 0 -15px; }
    .masthead ul.menu {
      margin: 0;
      float: none;
      clear: both;
      display: block;
      padding: 0 15px; }
      .masthead ul.menu > li {
        display: block;
        float: none;
        text-align: center; }
        .masthead ul.menu > li:not(:last-child) {
          border-bottom: 1px solid rgba(77, 85, 91, 0.8); }
        .masthead ul.menu > li a {
          font-size: 16px !important; } }
  .masthead .toggle-menu {
    display: none;
    position: absolute;
    top: 6px;
    right: 15px;
    height: 42px;
    padding: 0 40px 0 8px;
    line-height: 42px;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px; }
    .masthead .toggle-menu .text {
      float: left; }
    .masthead .toggle-menu .bars {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      width: 22px;
      height: 17px; }
      .masthead .toggle-menu .bars .bar {
        position: absolute;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.9);
        height: 1px; }
        .masthead .toggle-menu .bars .bar:nth-child(2n) {
          top: 8px; }
        .masthead .toggle-menu .bars .bar:last-child {
          bottom: 0; }
    @media screen and (max-width: 25.8125em) {
      .masthead .toggle-menu .text {
        display: none; } }

input#checkMenuToggle {
  position: absolute;
  display: none; }

@media screen and (max-width: 50em) {
  input#checkMenuToggle:checked ~ .masthead {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.96);
    -webkit-transition: background-color .5s, height .5s ease-in;
    -moz-transition: background-color .5s, height .5s ease-in;
    -ms-transition: background-color .5s, height .5s ease-in;
    transition: background-color .5s, height .5s ease-in;
    overflow-y: scroll; }
  .masthead .toggle-menu {
    display: block; } }

.nav-no-scroll, .nav-nobody {
  overflow: hidden;
  height: 100%; }

.scrollNav {
  margin-top: 6px;
  background-color: rgba(255, 255, 255, 0.9); }
  .scrollNav ul {
    list-style: none;
    padding: 0;
    display: table;
    margin: 0 auto; }
    .scrollNav ul > li {
      display: inline-block; }
      .scrollNav ul > li:not(:last-of-type) {
        margin-right: 18px;
        position: relative; }
        .scrollNav ul > li:not(:last-of-type):after {
          content: '';
          background: url(/images/sprite-main/bullet-subnav.png) center center no-repeat;
          position: absolute;
          z-index: 2;
          top: 14px;
          right: -12px; }
      .scrollNav ul > li a {
        font-size: 14px;
        color: #9B9B9B;
        text-transform: uppercase;
        padding: 6px 8px;
        display: block;
        border-radius: 3px; }
        .scrollNav ul > li a:hover {
          background-color: rgba(37, 170, 225, 0.1); }
        @media print, screen and (min-width: 64em) {
          .scrollNav ul > li a {
            padding: 6px 16px; } }
  @media screen and (max-width: 49.375em) {
    body.about .scrollNav {
      display: none; } }

figure {
  padding: 0;
  margin: 0; }

.content {
  position: relative;
  z-index: 1;
  background-color: #FFF; }

main {
  max-width: 100%;
  overflow: hidden; }

.relative {
  position: relative; }

.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .vertical-center-medium {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; } }

.full-width-max {
  max-width: 100%;
  height: auto; }

.clearfix, .quotes .attribution, .labsCallout a {
  display: inline-block; }
  .clearfix:after, .quotes .attribution:after, .labsCallout a:after, .clearfix:before, .quotes .attribution:before, .labsCallout a:before {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0; }

.button {
  background-image: linear-gradient(-140deg, #25A8DF 0%, #1F9ACE 100%);
  font-size: 16px;
  padding: 16px 34px;
  text-transform: uppercase; }
  .button:hover {
    background-image: linear-gradient(-140deg, #1e99cc 0%, #1c89b8 100%); }
  .button.single-button {
    margin-right: 0; }

.no-pad-b, .no-pad-bottom {
  padding-bottom: 0 !important; }

.no-pad-t, .no-pad-top {
  padding-top: 0 !important; }

.no-pad {
  padding: 0 !important; }

.border-b {
  border-bottom: 1px solid #e2e8eb; }

.border-t {
  border-top: 1px solid #e2e8eb; }

.bump-top {
  margin-top: 25px; }

.bump-top-2x {
  margin-top: 40px; }

.bump-top-3x {
  margin-top: 80px; }

@media screen and (max-width: 74.9375em) {
  .bump-top-largeDown {
    margin-top: 25px; } }

@media print, screen and (min-width: 64em) {
  .bump-top-large-2x {
    margin-top: 40px; } }

.nudge-top {
  margin-top: 10px !important; }

.bump-bottom {
  margin-bottom: 25px; }

.bump-bottom-2x {
  margin-bottom: 40px; }

.bump-bottom-3x {
  margin-bottom: 60px; }

.bump-left {
  margin-left: 25px; }

.bump-left-2x {
  margin-left: 40px; }

.bump-right {
  margin-right: 25px; }

.bump-right-2x {
  margin-right: 40px; }

.bump-slight-top {
  margin-top: 12px; }

.bump-top-small {
  margin-top: 25px; }

.bump-top-small-2x {
  margin-top: 40px; }

.nudge-top-small {
  margin-top: 10px; }

.bump-bottom-small {
  margin-bottom: 25px; }

.bump-bottom-small-2x {
  margin-bottom: 40px; }

.belowTitle {
  padding-top: 72px; }

.scrunch-top {
  margin-top: -20px; }

.scrunch-bottom {
  margin-bottom: -20px; }

.scrunch-bottom-sm {
  margin-bottom: -10px !important; }

.pad-top {
  padding-top: 25px; }

.pad-top-2x {
  padding-top: 40px; }

.pad-top-3x {
  padding-top: 45px; }

.no-padding {
  padding: 0 !important; }

.pad-bottom {
  padding-bottom: 25px; }

.pad-bottom-2x {
  padding-bottom: 40px; }

.pad-left {
  padding-left: 25px; }

.pad-left-2x {
  padding-left: 40px; }

.pad-right {
  padding-right: 25px; }

.pad-right-2x {
  padding-right: 40px; }

.no-margin-b {
  margin-bottom: 0; }

.no-margin-t {
  margin-top: 0; }

@media screen and (max-width: 74.9375em) {
  .no-margin-t-largeDown {
    margin-top: 0 !important; } }

.no-pad-b {
  padding-bottom: 0 !important; }

.no-pad-t {
  padding-top: 0 !important; }

@media screen and (max-width: 74.9375em) {
  .no-pad-t-largeDown {
    padding-top: 0 !important; } }

@media screen and (max-width: 39.9375em) {
  .no-pad-t-small {
    padding-top: 0 !important; } }

@media screen and (max-width: 39.9375em) {
  .no-pad-b-small {
    padding-bottom: 0 !important; } }

.z1 {
  z-index: 1; }

.position-relative {
  position: relative; }

@media print, screen and (min-width: 40em) {
  .bump-top-medium {
    margin-top: 25px; }
  .bump-top-medium-2x {
    margin-top: 40px; }
  .bump-top-medium-3x {
    margin-top: 80px; }
  .bump-bottom-medium {
    margin-bottom: 25px; }
  .bump-bottom-medium-2x {
    margin-bottom: 40px; }
  .bump-bottom-medium-3x {
    margin-bottom: 80px; }
  .bump-left-medium {
    margin-left: 25px; }
  .bump-left-medium-2x {
    margin-left: 40px; }
  .bump-right-medium {
    margin-right: 25px; }
  .bump-right-medium-2x {
    margin-right: 40px; }
  .pad-top-medium {
    padding-top: 25px; }
  .pad-top-medium-2x {
    padding-top: 40px; }
  .pad-right-medium-2x {
    padding-right: 40px; }
  .pad-top-medium-3x {
    padding-top: 45px; }
  hr.divider {
    margin: 18px -10px 20px; }
  .medium-no-max {
    max-width: none; }
    .medium-no-max.bleed-left {
      float: right; } }

.slice {
  display: block;
  position: relative;
  padding: 40px 0;
  clear: both; }
  .slice.double-pad-top, .slice.double-padding {
    padding-top: 80px; }
  .slice.double-pad-bottom, .slice.double-padding {
    padding-bottom: 80px; }
  @media print, screen and (min-width: 40em) {
    .slice.double-padding-medium {
      padding-top: 80px;
      padding-bottom: 80px; } }
  .slice.mainImg {
    margin-top: 0;
    min-height: 170px;
    position: relative;
    color: #FFF;
    height: 431px; }
    @media screen and (max-width: 50em) {
      .slice.mainImg:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; } }
    @media print, screen and (min-width: 40em) {
      .slice.mainImg {
        min-height: 390px; } }
    .slice.mainImg #fillBelowNav {
      position: absolute;
      top: 53px;
      left: 0;
      right: 0;
      bottom: 0; }
    .slice.mainImg.imgBg {
      background-color: #25aae1;
      background-position: center center;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      padding: 60px 0;
      position: relative; }
      .slice.mainImg.imgBg .imgSliceCaption {
        color: #FFF;
        color: rgba(255, 255, 255, 0.5);
        position: absolute;
        right: 12px;
        bottom: 12px;
        font-size: 12px;
        margin: 0;
        line-height: 1em; }
    .slice.mainImg .scaler {
      transform: scale(1.1);
      -webkit-transition: transform 2s ease-in-out;
      -moz-transition: transform 2s ease-in-out;
      -o-transition: transform 2s ease-in-out;
      transition: transform 2s ease-in-out; }
      .slice.mainImg .scaler.zoomBack {
        transform: scale(1); }
  .slice.bg-blue {
    background-color: #25aae1; }
  .slice.center-line {
    padding: 60px 0; }
  .slice.leadSection {
    padding: 80px 0 70px;
    text-align: center; }
  .slice.border-bottom {
    border-bottom: 1px solid #F3F3F3; }
  .slice .row {
    position: relative; }

.textureBg {
  background: url("/images/GridTile.png") top center/196px; }
  .textureBg.leadSection {
    background: url("/images/GridTile-darker.png") top center/196px;
    background-color: #F3F3F3; }

.fill {
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.content-bleed-off {
  overflow: hidden; }

.content-bleed-off-x {
  overflow: visible; }
  .content-bleed-off-x .bleed-content {
    overflow-x: hidden;
    max-width: 100%; }

.imgBg {
  background-color: #25aae1;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative; }
  .imgBg .bgLg, .imgBg .bgTiny {
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }
  .imgBg .bgLg.fadeIn {
    transition: opacity, .3s, transform, ease-in-out, 2s; }
  .imgBg .bgLg {
    background-image: url("/images/empty.gif"); }
  @media print, screen and (min-width: 40em) {
    .imgBg .bgSm {
      background-image: url("/images/empty.gif"); } }

.blue-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #27AAE1;
  opacity: 0.92; }

.lightGray {
  background-color: #f4f6f7; }

.center-line {
  padding: 0 !important; }
  .center-line .bg-line {
    min-height: 40px;
    background: url("/images/bg-line-dark.gif") center top repeat-y;
    position: relative; }
    .center-line .bg-line.top {
      left: 0;
      right: 0;
      top: 0;
      margin-bottom: 15px;
      min-height: 40px; }
      .center-line .bg-line.top.minus1px {
        top: -1px; }
    .center-line .bg-line.top-o {
      margin-top: 15px; }
      .center-line .bg-line.top-o:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #056E9A;
        box-sizing: border-box;
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-8px); }
      .center-line .bg-line.top-o.above-previous {
        margin-top: -40px;
        min-height: 80px; }
    .center-line .bg-line.bottom {
      left: 0;
      right: 0;
      bottom: 0;
      margin-top: 15px; }
  .center-line.light-on-dark, .center-line.dayCount {
    color: #FFF; }
    .center-line.light-on-dark .bg-line, .center-line.dayCount .bg-line {
      background-image: url("/images/bg-line-light.gif"); }
      .center-line.light-on-dark .bg-line.top-o:before, .center-line.dayCount .bg-line.top-o:before {
        border: 1px solid #FFF; }

.center-line--1px-cover {
  width: 100%;
  display: flex;
  height: 0;
  display: flex;
  justify-content: center;
  overflow: visible; }
  .center-line--1px-cover:after {
    content: '';
    flex: 0 0 1px;
    height: 3px;
    background-color: #056E9A; }

.carousel .carousel-cell {
  width: 100%; }

.carousel .flickity-prev-next-button {
  background-color: transparent; }
  .carousel .flickity-prev-next-button svg {
    display: none; }
  .carousel .flickity-prev-next-button.previous {
    background: url(/images/sprite-main/carousel-arrow-prev.png) center center no-repeat;
    left: 0; }
  .carousel .flickity-prev-next-button.next {
    right: 0;
    background: url(/images/sprite-main/carousel-arrow-next.png) center center no-repeat; }

.carousel .flickity-page-dots .dot {
  background-color: #FFF; }

.box {
  display: block; }
  .box.gray-border {
    border: 1px solid #CACACA; }
  .box.form-box {
    padding: 0 15px; }

.scale-container {
  overflow: hidden;
  max-width: 100%; }

.drop-shadow {
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.3); }

.bottom-center {
  text-align: center;
  position: relative;
  z-index: 2; }

@media print, screen and (min-width: 40em) {
  .mobile-carousel {
    display: none; } }

#kickass-hello-sunshine {
  display: none; }

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none; } }

body {
  color: #4D555B; }

h2 {
  font-size: 32px;
  line-height: 38px;
  color: #056E9A;
  text-transform: uppercase; }

h3 {
  font-family: Dosis, Roboto, sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 3px;
  position: relative;
  margin-bottom: 16px; }
  .text-content h3:after, .entry-content h3:after, .text-node h3:after {
    content: '';
    position: absolute;
    background-color: #25aae1;
    width: 94px;
    height: 1px;
    bottom: -7px;
    left: 0; }
  .text-content h3.no-rule:after, .entry-content h3.no-rule:after, .text-node h3.no-rule:after {
    background: none; }
  .light-on-dark .text-content h3:after, .light-on-dark .entry-content h3:after, .light-on-dark .text-node h3:after {
    background-color: #FFF; }
  h3.center-rule:after {
    left: 50% !important;
    transform: translateX(-50%); }
  @media print, screen and (min-width: 40em) {
    .right-side h3:after, .text-right h3:after {
      left: auto;
      right: 1px; }
    h3 h2 {
      font-size: 38px;
      line-height: 38px; } }

h4 {
  color: #25aae1;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 12px; }


p,
#map #contact-map-box h4 {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 12px; }
  p.largerText, #map #contact-map-box h4.largerText, p.biggerText, #map #contact-map-box h4.biggerText {
    font-size: 20px; }
  p strong, #map #contact-map-box h4 strong {
    color: #000000; }

a.arrow-link {
  white-space: pre; }

.mainImg h1, .mainImg h2, .mainImg h3, .mainImg p, .mainImg #map #contact-map-box h4, #map #contact-map-box .mainImg h4, .blue-overlay h1, .blue-overlay h2, .blue-overlay h3, .blue-overlay p, .blue-overlay #map #contact-map-box h4, #map #contact-map-box .blue-overlay h4 {
  color: #FFF;
  text-align: center; }

.mainImg h1, .blue-overlay h1 {
  font-size: 64px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 12px;
  text-transform: uppercase;
  line-height: 65px; }
  .mainImg h1:after, .blue-overlay h1:after {
    content: '';
    position: absolute;
    width: 94px;
    height: 1px;
    background-color: #FFF;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }

.mainImg h2, .blue-overlay h2 {
  font-family: Dosis, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 3px;
  line-height: 31px; }

@media screen and (max-width: 39.9375em) {
  .mainImg h1, .blue-overlay h1 {
    font-size: 50px;
    line-height: 54px; }
  .mainImg h2, .blue-overlay h2 {
    font-size: 21px;
    line-height: 26px; }
  body.case-study .mainImg h1, body.case-study .blue-overlay h1 {
    font-size: 35px;
    line-height: 40px; }
  body.case-study .mainImg h2, body.case-study .blue-overlay h2 {
    font-size: 18px;
    line-height: 23px; } }

@media screen and (max-width: 25em) {
  .mainImg h1, .blue-overlay h1 {
    font-size: 43px;
    line-height: 44px; }
  .mainImg h2, .blue-overlay h2 {
    font-size: 20px; } }

.imgBg h1, .imgBg h2, .imgBg h3, .imgBg p, .imgBg #map #contact-map-box h4, #map #contact-map-box .imgBg h4 {
  color: #FFF; }

p.lead, #map #contact-map-box h4.lead, .supporting-text {
  font-size: 20px;
  line-height: 31px;
  color: #056E9A;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  @media print, screen and (min-width: 64em) {
    p.lead, #map #contact-map-box h4.lead, .supporting-text {
      width: 660px; } }

h2 + p.lead, #map #contact-map-box h2 + h4.lead, h2 + .supporting-text {
  margin-top: 10px; }

.titleEchoArea {
  position: relative;
  padding-top: 40px; }
  .titleEchoArea h1, .titleEchoArea h2, .titleEchoArea h3, .titleEchoArea p, .titleEchoArea #map #contact-map-box h4, #map #contact-map-box .titleEchoArea h4 {
    margin-bottom: 0; }
  .titleEchoArea .echoText {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    opacity: 0.69;
    font-size: 144px;
    color: #F3F3F3;
    line-height: 144px;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
    display: none; }
    @media print, screen and (min-width: 40em) {
      .titleEchoArea .echoText {
        display: block; } }

.introText p, .introText #map #contact-map-box h4, #map #contact-map-box .introText h4 {
  font-size: 22px; }

.mainImg .introText {
  position: relative; }
  .mainImg .introText p, .mainImg .introText #map #contact-map-box h4, #map #contact-map-box .mainImg .introText h4, .mainImg .introText h1, .mainImg .introText h2 {
    color: #FFF; }

.linkList p, .linkList #map #contact-map-box h4, #map #contact-map-box .linkList h4, .linkList li {
  margin-bottom: 0; }

.blend-links a {
  color: #4D555B;
  padding-right: 18px;
  position: relative; }
  .blend-links a:after {
    content: '';
    position: absolute;
    right: 0;
    top: 4px;
    background: url(/images/sprite-main/icn-inlinelink.png) center center no-repeat; }
  .blend-links a:hover {
    color: #25aae1; }
    .blend-links a:hover:after {
      background: url(/images/sprite-main/icn-inlinelink-hover.png) center center no-repeat; }

.servingList p, .servingList #map #contact-map-box h4, #map #contact-map-box .servingList h4 {
  font-size: 16px;
  line-height: 26px; }

.quotes {
  padding: 75px 0;
  text-align: center; }
  .quotes .quote {
    position: relative;
    padding-top: 60px; }
    .quotes .quote:before {
      content: '';
      background: url(/images/quote-marks.svg);
      display: block;
      width: 24px;
      height: 17px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%); }
  .quotes .quote, .quotes .quote p, .quotes .quote #map #contact-map-box h4, #map #contact-map-box .quotes .quote h4 {
    font-size: 24px;
    color: #FFF;
    line-height: 35px;
    margin-bottom: 20px;
    position: relative; }
  .quotes .attribution {
    font-family: Dosis, Roboto, sans-serif;
    font-size: 16px;
    color: #FFF;
    text-transform: uppercase;
    padding-bottom: 15px;
    display: block; }
  @media screen and (max-width: 39.9375em) {
    .quotes .quote {
      padding-top: 30px; }
    .quotes .attribution {
      padding-bottom: 24px; }
    .quotes .quote, .quotes .quote p, .quotes .quote #map #contact-map-box h4, #map #contact-map-box .quotes .quote h4 {
      font-size: 18px;
      line-height: 28px; } }

blockquote.quote {
  border: none;
  padding: 0;
  margin: 0 0 34px; }
  blockquote.quote p, blockquote.quote #map #contact-map-box h4, #map #contact-map-box blockquote.quote h4 {
    line-height: 25px; }

p.quote-source, #map #contact-map-box h4.quote-source, blockquote.quote p.quote-source, blockquote.quote #map #contact-map-box h4.quote-source, #map #contact-map-box blockquote.quote h4.quote-source {
  font-family: Dosis;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 18px; }

footer h1 {
  font-family: Dosis, Roboto, sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  color: #CACACA;
  letter-spacing: 3px;
  margin-bottom: 0; }

footer h2 {
  font-size: 16px;
  color: #CACACA;
  text-transform: none;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 38px; }

footer p, footer #map #contact-map-box h4, #map #contact-map-box footer h4 {
  color: #747E83;
  font-size: 15px;
  margin-bottom: 8px;
  padding: 0;
  line-height: 21px; }

@media screen and (max-width: 39.9375em) {
  footer h1#footer-greeting {
    line-height: 26px; }
  footer ul#footer-nav {
    margin-top: 10px; } }

.text-2-columns, .text-2-columns-medium,
.text-3-columns, .text-3-columns-medium,
.text-4-columns, .text-4-columns-medium,
.text-5-columns, .text-5-columns-medium,
.text-6-columns, .text-6-columns-medium {
  column-gap: 30px; }

.text-2-columns {
  column-count: 2; }

.text-3-columns {
  column-count: 3; }

.text-4-columns {
  column-count: 4; }

.text-5-columns {
  column-count: 5; }

.text-6-columns {
  column-count: 6; }

@media print, screen and (min-width: 40em) {
  .text-2-columns-medium {
    column-count: 2; }
  .text-3-columns-medium {
    column-count: 3; }
  .text-4-columns-medium {
    column-count: 4; }
  .text-5-columns-medium {
    column-count: 5; }
  .text-6-columns-medium {
    column-count: 6; } }

.disclaimer, p.disclaimer, #map #contact-map-box h4.disclaimer {
  font-size: 14px; }

.light-on-dark h1, .light-on-dark h2, .light-on-dark h3, .light-on-dark p, .light-on-dark #map #contact-map-box h4, #map #contact-map-box .light-on-dark h4, .light-on-dark a {
  color: #FFF; }

.light-on-dark a {
  font-weight: 500; }
  .light-on-dark a:hover {
    text-decoration: underline; }

@media screen and (max-width: 39.9375em) {
  .small-text-center {
    text-align: center; } }

a.case-study-item {
  color: white;
  background-color: #4D555B;
  position: relative;
  overflow: hidden;
  background-size: cover;
  margin-bottom: 8px;
  display: block; }
  a.case-study-item .csRow {
    position: relative;
    width: 1160px;
    max-width: 100%;
    margin: 0 auto; }
  a.case-study-item .overlay-details {
    float: left;
    text-align: right;
    position: relative;
    padding: 0 55px 0 10px; }
    a.case-study-item .overlay-details h3 {
      line-height: 25px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: block; }
    a.case-study-item .overlay-details p.desc, a.case-study-item .overlay-details #map #contact-map-box h4.desc, #map #contact-map-box a.case-study-item .overlay-details h4.desc {
      font-size: 15.5px;
      color: #FFFFFF;
      line-height: 26px;
      padding-right: 55px;
      padding-left: 10px; }
  a.case-study-item .secondary-details {
    width: 52%;
    float: left;
    margin-top: 167px;
    padding: 0 30px;
    position: relative;
    z-index: 20; }
  a.case-study-item .faux-link {
    text-transform: uppercase;
    line-height: 36px;
    position: relative;
    padding-right: 45px;
    display: table; }
    a.case-study-item .faux-link:after {
      content: '';
      border: 1px solid rgba(255, 255, 255, 0.8);
      position: absolute;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      right: 0;
      top: 0;
      background: url(/images/arrow-inside-circle.png) 13px 9px no-repeat; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        a.case-study-item .faux-link:after {
          background-image: url(/images/arrow-inside-circle@2x.png);
          background-size: 10px 16px; } }
  a.case-study-item .above-line {
    height: 145px;
    position: relative; }
  a.case-study-item .below-line {
    position: absolute;
    top: 167px;
    left: 0;
    right: 0; }
  a.case-study-item .overlay {
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 30%; }
  a.case-study-item .cs-line {
    position: absolute;
    left: 100px;
    top: 152px;
    right: 15%;
    height: 1px;
    background-color: #FFF; }
    @media screen and (max-width: 39.9375em) {
      a.case-study-item .cs-line {
        top: 93px; } }
  a.case-study-item .darkener {
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-in-out;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out; }
  a.case-study-item.dmc {
    background-image: url("/images/work/cs-backdrop-dmc.jpg"); }
    a.case-study-item.dmc .overlay, a.case-study-item.dmc .overlay-mobile {
      background: url("/images/work/dmc/bg-greenPattern.jpg") top right/cover;
      -webkit-transition: opacity 0.4s ease-in-out;
      -moz-transition: opacity 0.4s ease-in-out;
      -o-transition: opacity 0.4s ease-in-out;
      transition: opacity 0.4s ease-in-out; }
    a.case-study-item.dmc:hover .overlay {
      opacity: .85; }
    a.case-study-item.dmc:hover .faux-link:after {
      background-color: rgba(107, 166, 23, 0.85); }
    a.case-study-item.dmc .secondary-details {
      padding-right: 231px; }
    a.case-study-item.dmc .darkener {
      background-color: #091B2B; }
    a.case-study-item.dmc .device img {
      bottom: -3px; }
  a.case-study-item.custom-alarm {
    background-image: url(/images/work/cs-backdrop-customalarm.jpg); }
    a.case-study-item.custom-alarm .overlay {
      background-color: rgba(39, 170, 225, 0.6); }
    a.case-study-item.custom-alarm .overlay-mobile {
      background-color: #2678ad; }
    a.case-study-item.custom-alarm .device img {
      bottom: -12px !important; }
    a.case-study-item.custom-alarm .secondary-details {
      padding-right: 251.5px; }
    a.case-study-item.custom-alarm .darkener {
      background-color: #091B2B; }
    a.case-study-item.custom-alarm:hover .faux-link:after {
      background-color: rgba(39, 170, 225, 0.85); }
  a.case-study-item.face-aging-mn {
    background-image: url(/images/work/cs-backdrop-faceaging.jpg);
    background-position: top center; }
    a.case-study-item.face-aging-mn .overlay {
      background-color: rgba(213, 64, 36, 0.6); }
    a.case-study-item.face-aging-mn .overlay-mobile {
      background-color: #D54024; }
    a.case-study-item.face-aging-mn .device img {
      bottom: -32px !important; }
    a.case-study-item.face-aging-mn .secondary-details {
      padding-right: 221.5px; }
    a.case-study-item.face-aging-mn .darkener {
      background-color: #5f1d10;
      opacity: 0.4; }
    a.case-study-item.face-aging-mn:hover .faux-link:after {
      background-color: rgba(213, 64, 36, 0.85); }
  a.case-study-item.hubsource-workforce-platform {
    background-image: url(/images/work/cs-backdrop-hubsource.jpg);
    background-position: center center; }
    a.case-study-item.hubsource-workforce-platform .overlay {
      background-color: rgba(54, 161, 34, 0.85); }
    a.case-study-item.hubsource-workforce-platform .overlay-mobile {
      background-color: #36A122;
      margin-top: -10px; }
    a.case-study-item.hubsource-workforce-platform .device img {
      bottom: -22px !important; }
    a.case-study-item.hubsource-workforce-platform .secondary-details {
      padding-right: 270px; }
    a.case-study-item.hubsource-workforce-platform .darkener {
      background-color: #102f0a;
      opacity: 0.55; }
    a.case-study-item.hubsource-workforce-platform:hover .faux-link:after {
      background-color: rgba(54, 161, 34, 0.85); }
  a.case-study-item.hayfield {
    background-image: url(/images/work/cs-backdrop-hayfield.jpg);
    background-position: center center; }
    a.case-study-item.hayfield .overlay {
      background-color: rgba(16, 126, 67, 0.83); }
    a.case-study-item.hayfield .overlay-mobile {
      background-color: #107E43;
      margin-top: 4px; }
    a.case-study-item.hayfield .device {
      right: 17% !important; }
      a.case-study-item.hayfield .device img {
        bottom: 10px !important; }
    a.case-study-item.hayfield .secondary-details {
      padding-right: 120px; }
    a.case-study-item.hayfield .darkener {
      opacity: 0.57;
      background: #040404; }
    a.case-study-item.hayfield:hover .faux-link:after {
      background-color: rgba(16, 126, 67, 0.85); }
    @media screen and (max-width: 39.9375em) {
      a.case-study-item.hayfield .device {
        width: 360px;
        max-width: 100%;
        margin: 0 auto; } }
  a.case-study-item.top-tier {
    background-image: url(/images/work/cs-backdrop-toptier.jpg); }
    a.case-study-item.top-tier .overlay {
      background-color: rgba(129, 93, 136, 0.7); }
    a.case-study-item.top-tier .secondary-details {
      padding-right: 189.5px; }
    a.case-study-item.top-tier .device img {
      bottom: -20px !important; }
    a.case-study-item.top-tier .darkener {
      background-color: #2E1A31; }
    a.case-study-item.top-tier:hover .faux-link:after {
      background-color: rgba(129, 93, 136, 0.85); }
  a.case-study-item.travel-health-app {
    background-image: url(/images/work/cs-backdrop-paris.jpg); }
    a.case-study-item.travel-health-app .overlay {
      background-color: rgba(28, 92, 133, 0.75); }
    a.case-study-item.travel-health-app .overlay-mobile {
      background-color: #1C5C85; }
    a.case-study-item.travel-health-app .darkener {
      background-color: #091B2B; }
    a.case-study-item.travel-health-app .device img {
      bottom: -16px !important; }
    a.case-study-item.travel-health-app:hover .faux-link:after {
      background-color: rgba(37, 121, 175, 0.85); }
  a.case-study-item .dmc {
    background-image: url(/images/work/cs-backdrop-dmc@1024w.jpg); }
  a.case-study-item .travel-health-app {
    background-image: url(/images/work/cs-backdrop-paris@1024w.jpg); }

@media print, screen and (min-width: 40em) {
  a.case-study-item {
    height: 362px; }
    a.case-study-item .bg-mobile {
      background-image: none !important; }
    a.case-study-item:hover .darkener {
      opacity: .6; }
    a.case-study-item:hover .device {
      transform: translateY(-10px); }
    a.case-study-item .overlay-details {
      width: 47%; }
    a.case-study-item .darkener {
      background-color: #000000;
      opacity: .61;
      -webkit-transition: opacity 0.4s ease-in-out;
      -moz-transition: opacity 0.4s ease-in-out;
      -o-transition: opacity 0.4s ease-in-out;
      transition: opacity 0.4s ease-in-out; }
    a.case-study-item .devicePosition {
      width: 1160px;
      max-width: 100%;
      margin: auto;
      position: absolute;
      bottom: 0;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5; }
      a.case-study-item .devicePosition .device {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15%;
        overflow: visible;
        width: 1px;
        -webkit-transition: transform 0.4s ease-in-out;
        -moz-transition: transform 0.4s ease-in-out;
        -o-transition: transform 0.4s ease-in-out;
        transition: transform 0.4s ease-in-out; }
        a.case-study-item .devicePosition .device img {
          position: absolute;
          right: 0;
          bottom: 0;
          max-width: none;
          transform: translateX(50%); }
    a.case-study-item:hover .darkener {
      opacity: .75; }
    a.case-study-item .secondary-details {
      padding-right: 190px; } }

@media screen and (max-width: 63.9375em) {
  .case-study-item .overlay-details .below-line {
    position: relative;
    top: auto; }
    .case-study-item .overlay-details .below-line .desc {
      padding: 24px 0 0; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  a.case-study-item .secondary-details {
    margin-top: 20px;
    width: 47%;
    clear: both;
    padding-right: 55px !important; }
    a.case-study-item .secondary-details .faux-link {
      float: right; } }

@media screen and (max-width: 39.9375em) {
  a.case-study-item {
    background-image: none !important; }
    a.case-study-item .bg-mobile {
      position: absolute;
      height: 270px;
      left: 0;
      right: 0;
      top: 0; }
    a.case-study-item.dmc .bg-mobile {
      background: url("/images/work/cs-backdrop-dmc@1024w.jpg") no-repeat center center/cover; }
    a.case-study-item.custom-alarm .bg-mobile {
      background: url("/images/work/cs-backdrop-customalarm1000w.jpg") no-repeat bottom left/cover; }
    a.case-study-item.travel-health-app .bg-mobile {
      background: url(/images/work/cs-backdrop-paris%401024w.jpg) no-repeat center center/cover; }
    a.case-study-item .overlay {
      display: none; }
    a.case-study-item .overlay-mobile {
      background-color: rgba(37, 170, 225, 0.9);
      margin-top: -20px;
      position: relative;
      z-index: 40; }
      a.case-study-item .overlay-mobile:before, a.case-study-item .overlay-mobile:after {
        content: '';
        display: block;
        clear: both; }
    a.case-study-item .devicePosition {
      width: 100%;
      padding: 15px 15px 0 15px;
      margin: 0 auto;
      text-align: center;
      position: relative;
      z-index: 1; }
      a.case-study-item .devicePosition .device img {
        max-height: 270px; }
    a.case-study-item .overlay-details {
      width: 90%;
      padding: 0 15px; }
    a.case-study-item .above-line {
      height: 88px; }
    a.case-study-item .line {
      top: 98px;
      right: 0; }
    a.case-study-item .secondary-details {
      margin: 0;
      width: 90%;
      padding: 15px 15px 25px !important; }
      a.case-study-item .secondary-details .faux-link {
        width: auto;
        float: right; } }

@media print, screen and (min-width: 64em) {
  a.case-study-item .overlay-details {
    width: 33%; } }

@media screen and (min-width: 75em) {
  a.case-study-item .overlay-details {
    width: 27%; }
  a.case-study-item .secondary-details {
    padding-right: 115px;
    width: 58%; }
  a.case-study-item.travel-health-app .overlay-details h3 {
    margin-left: -40px; } }

@media screen and (min-width: 1200px) and (max-width: 1220px) {
  a.case-study-item.travel-health-app .overlay-details h3 {
    font-size: 23px; } }

ul.serveList {
  list-style: none;
  margin-top: 40px; }
  ul.serveList li {
    font-size: 16px;
    line-height: 25px;
    color: #25aae1;
    margin-bottom: 12px;
    display: inline-block;
    width: 100%; }
    ul.serveList li a {
      text-decoration: underline; }
  @media print, screen and (min-width: 40em) {
    ul.serveList {
      -webkit-column-count: 2;
      /* Chrome, Safari, Opera */
      -moz-column-count: 2;
      /* Firefox */
      column-count: 2; } }
  @media print, screen and (min-width: 64em) {
    ul.serveList {
      -webkit-column-count: 3;
      /* Chrome, Safari, Opera */
      -moz-column-count: 3;
      /* Firefox */
      column-count: 3; } }

.labsCallout {
  padding: 0; }
  .labsCallout a {
    display: flex;
    position: relative; }
  .labsCallout .facet {
    height: 290px;
    flex-grow: 1;
    display: block;
    padding: 0;
    margin: 0; }
    .labsCallout .facet.fancoach {
      background-image: url(/images/work/labs-triptic-fc.jpg); }
    .labsCallout .facet.pixelpress {
      background-image: url(/images/work/labs-triptic-web.jpg); }
      @media print, screen and (min-width: 64em) {
        .labsCallout .facet.pixelpress {
          margin-left: -17px; } }
    .labsCallout .facet.rochesternow {
      background-image: url(/images/work/labs-triptic-rochnow.jpg); }
  .labsCallout .darkener {
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-transition: background-color 0.4s ease-in-out;
    -moz-transition: background-color 0.4s ease-in-out;
    -o-transition: background-color 0.4s ease-in-out;
    transition: background-color 0.4s ease-in-out; }
  .labsCallout:hover .darkener {
    background-color: rgba(0, 0, 0, 0.8); }
  .labsCallout .faux-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 36px;
    color: #FFFFFF;
    padding-right: 60px;
    white-space: nowrap;
    -webkit-transition: padding 0.4s ease-in-out;
    -moz-transition: padding 0.4s ease-in-out;
    -o-transition: padding 0.4s ease-in-out;
    transition: padding 0.4s ease-in-out; }
    .labsCallout .faux-link:after {
      content: '';
      background: url(/images/sprite-main/cta-arrow-o.png) center center no-repeat;
      display: block;
      width: 38px;
      height: 38px;
      position: absolute;
      display: block;
      right: 0;
      top: 7px; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .labsCallout .faux-link:after {
          background-image: url(/images/sprite-main/cta-arrow-o@2x.png);
          background-size: 100%; } }
  .labsCallout:hover .faux-link {
    padding-right: 65px; }
  @media screen and (max-width: 39.9375em) {
    .labsCallout .faux-link {
      white-space: nowrap;
      font-size: 24px;
      line-height: 50px;
      padding-right: 50px; }
    .labsCallout .facet {
      height: auto;
      padding-bottom: 37.5%; } }

ul.serveLogos {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin-top: 40px; }
  @media screen and (max-width: 63.9375em) {
    ul.serveLogos > li img {
      width: 80%; } }

.prevNextCaseSt {
  padding: 0; }
  .prevNextCaseSt h4 {
    color: #056E9A;
    margin: 0; }
  .prevNextCaseSt a {
    padding: 17px 4px;
    position: relative;
    display: block;
    color: #4D555B;
    -webkit-transition: background-color 0.4s ease-in-out 0.1s;
    -moz-transition: background-color 0.4s ease-in-out 0.1s;
    -o-transition: background-color 0.4s ease-in-out 0.1s;
    transition: background-color 0.4s ease-in-out 0.1s; }
    .prevNextCaseSt a:hover {
      background-color: #e8edef; }
    .prevNextCaseSt a:before {
      content: '';
      position: absolute;
      top: 35px; }
    @media screen and (min-width: 1070px) {
      .prevNextCaseSt a.nextCS {
        margin-right: -35px; }
      .prevNextCaseSt a.prevCS {
        margin-left: -35px; } }

.proj-aspect h1 {
  position: relative;
  margin: 50px 0 40px;
  line-height: 35px;
  text-transform: uppercase;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 30px;
  color: #1B65A2;
  display: flex; }
  @media print, screen and (min-width: 40em) {
    .proj-aspect h1 {
      margin: 40px 0 60px;
      height: 95px; } }
  .proj-aspect h1 .big-text {
    display: table;
    align-self: flex-end; }
    @media screen and (max-width: 39.9375em) {
      .proj-aspect h1 .big-text {
        text-align: center;
        display: block;
        width: 100%; } }

.proj-aspect .aspect-count {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translatex(-106%); }
  .proj-aspect .aspect-count .number {
    color: #F3F3F3;
    line-height: 96px;
    font-size: 144px;
    font-weight: 500;
    padding: 0;
    float: left;
    min-width: 140px;
    text-align: center; }
  .proj-aspect .aspect-count .simple-title {
    font-family: Dosis;
    font-size: 16px;
    color: #9B9B9B;
    letter-spacing: 1.5px;
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translate(-50%, 100%); }
  @media screen and (max-width: 39.9375em) {
    .proj-aspect .aspect-count .number, .proj-aspect .aspect-count .simple-title {
      display: none; } }

.proj-aspect.light-on-dark h1 {
  color: #FFF; }

.proj-aspect.light-on-dark .aspect-count .number {
  opacity: .2; }

.proj-aspect.light-on-dark .aspect-count .simple-title {
  color: #FFF; }

@media print, screen and (min-width: 64em) {
  .proj-aspect {
    line-height: 43px;
    font-size: 36px; } }

.lines-container {
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  width: 75rem;
  max-width: 100%;
  left: 50%;
  pointer-events: none; }

.line {
  position: absolute;
  display: none; }
  .line.attach-right {
    right: 0;
    padding-right: 15px; }
  .line.attach-left, .line.line-from-left {
    left: 0;
    padding-left: 15px; }
  .line.attach-bottom {
    bottom: 0; }
  .line.attach-top {
    top: 0; }
  .line.left-medium-offset-1, .line.left-medium-offset-2, .line.left-medium-offset-3, .line.left-medium-offset-4, .line.left-medium-offset-5, .line.left-medium-offset-6, .line.left-medium-offset-7, .line.left-medium-offset-8, .line.left-medium-offset-9 {
    padding-left: 15px; }
    .line.left-medium-offset-1.line-o, .line.left-medium-offset-2.line-o, .line.left-medium-offset-3.line-o, .line.left-medium-offset-4.line-o, .line.left-medium-offset-5.line-o, .line.left-medium-offset-6.line-o, .line.left-medium-offset-7.line-o, .line.left-medium-offset-8.line-o, .line.left-medium-offset-9.line-o {
      padding-left: 32px; }
  .line.small-top, .line.small-bottom {
    display: block;
    left: 50%;
    width: 1px;
    position: absolute; }
    .line.small-top .paint, .line.small-bottom .paint {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0; }
  .line.top-to-title {
    top: 196px;
    /* slice-margin + h1-top-margin + h1-height + dist-below */ }
    .double-padding .line.top-to-title, .double-padding-medium .line.top-to-title, .double-pad-top .line.top-to-title, .slice.double-padding .line.top-to-title {
      top: 236px;
      /* slice-margin + h1-top-margin + h1-height + dist-below */ }
  .line.small-top {
    top: 0px;
    height: 60px; }
    .line.small-top.top-o {
      top: 17px;
      height: 50px; }
  .line.bottom-to-title {
    height: 197px;
    /* slice-margin + h1-top-margin + h1-height + dist-below + overlap-line */ }
    .double-padding .line.bottom-to-title, .double-padding-medium .line.bottom-to-title, .double-pad-top .line.bottom-to-title, .slice.double-padding .line.bottom-to-title {
      height: 236px; }
  .line.small-bottom {
    bottom: 0;
    height: 40px; }
  .line.left-medium-offset-1 {
    left: 8.33333%; }
  .line.left-medium-offset-2 {
    left: 16.66667%; }
  .line.left-medium-offset-3 {
    left: 25%; }
  .line.left-medium-offset-4 {
    left: 33.33333%; }
  .line.left-medium-offset-5 {
    left: 41.66667%; }
  .line.left-medium-offset-6 {
    left: 50%; }
  .line.left-medium-offset-7 {
    left: 58.33333%; }
  .line.left-medium-offset-8 {
    left: 66.66667%; }
  .line.left-medium-offset-9 {
    left: 75%; }
  @media print, screen and (min-width: 64em) {
    .line.left-large-offset-8 {
      left: 66.66667%; } }
  @media print, screen and (min-width: 40em) {
    .line {
      display: block; }
      .line.small-top, .line.small-bottom {
        display: none; } }

.line-o:before {
  content: '';
  height: 17px;
  width: 17px;
  border-radius: 50%;
  display: block;
  position: absolute;
  border: 1px solid #CACACA; }

.line-o.line-h:before {
  left: 15px;
  top: 0;
  transform: translateY(-50%); }

.top-o:before {
  content: '';
  height: 17px;
  width: 17px;
  border-radius: 50%;
  display: block;
  position: absolute;
  border: 1px solid #CACACA;
  transform: translate(-8px, -100%); }

.paint {
  background-color: #CACACA;
  display: block; }
  .line-h .paint {
    height: 1px;
    width: 100%; }
  .line-v .paint {
    width: 1px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0; }

.line-v.attach-right {
  right: 15px;
  padding: 0; }

.resize-to-edge {
  overflow: visible; }

.cs-callout, .cs-callout p, .cs-callout #map #contact-map-box h4, #map #contact-map-box .cs-callout h4 {
  font-family: Dosis;
  font-size: 20px;
  line-height: 31px;
  text-transform: uppercase;
  color: #1B65A2;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 30px; }
  @media print, screen and (min-width: 40em) {
    .cs-callout, .cs-callout p, .cs-callout #map #contact-map-box h4, #map #contact-map-box .cs-callout h4 {
      text-align: right; } }
  @media print, screen and (min-width: 64em) {
    .cs-callout, .cs-callout p, .cs-callout #map #contact-map-box h4, #map #contact-map-box .cs-callout h4 {
      font-size: 24px;
      letter-spacing: 3px; } }

.quote-marks {
  height: 80px;
  background: url(/images/quote-marks.svg) center 20px no-repeat; }

br.show-for-list-view {
  display: none; }

body.work-list br.show-for-list-view {
  display: inline-block; }

.mobile-screenshot-carousel {
  width: 100%; }
  .mobile-screenshot-carousel .carousel-cell {
    width: 100%;
    display: block;
    padding: 20px 10px; }
  @media print, screen and (min-width: 40em) {
    .mobile-screenshot-carousel {
      display: none; } }

.cta-band .cta-viewWork {
  text-align: center; }

.cta-band .cta-nextCS {
  text-align: right; }

.three-areas {
  width: 1004px;
  max-width: 100%;
  margin: 10px auto; }
  .three-areas .bubble {
    background-color: rgba(37, 170, 225, 0.15);
    border-radius: 50%;
    width: 108%;
    padding-bottom: 108%;
    position: relative; }
    .three-areas .bubble.bubble-left {
      margin-right: -8%;
      border: 1px solid red; }
    .three-areas .bubble.bubble-middle {
      margin: 0 -4%; }
    .three-areas .bubble.bubble-right {
      float: right; }
    .three-areas .bubble .inside-bubble {
      width: 154px;
      position: absolute;
      text-align: center;
      left: 50%;
      top: 41px;
      transform: translateX(-50%); }
      .three-areas .bubble .inside-bubble .icon {
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px; }
        .three-areas .bubble .inside-bubble .icon img.icon-heart {
          transform: translateX(-8px); }
      .three-areas .bubble .inside-bubble h3 {
        color: #25aae1;
        margin-bottom: 0; }
      .three-areas .bubble .inside-bubble p, .three-areas .bubble .inside-bubble #map #contact-map-box h4, #map #contact-map-box .three-areas .bubble .inside-bubble h4 {
        font-size: 16px;
        line-height: 25px; }
      @media screen and (min-width: 59.375em) {
        .three-areas .bubble .inside-bubble {
          top: 51px; }
          .three-areas .bubble .inside-bubble .icon {
            margin-bottom: 16px; } }
    .three-areas .bubble.care .icon {
      padding-top: 13px; }
    .three-areas .bubble.innovation .icon {
      padding-top: 16px; }
  @media screen and (max-width: 39.9375em) {
    .three-areas {
      width: 360px;
      max-width: 100%; }
      .three-areas .bubble {
        width: 100%;
        padding-bottom: 100%;
        float: none !important;
        margin: 0 0 -8% 0 !important; } }

.dayCount {
  background: url("/images/about/bg-daycount@1024w.jpg") no-repeat center center/cover; }
  @media print, screen and (min-width: 40em) {
    .dayCount {
      background: url("/images/about/bg-daycount.jpg") no-repeat center center/cover; } }
  .dayCount #dayNumb {
    font-weight: 500;
    font-size: 96px;
    line-height: 144px;
    color: #FFF; }
  .dayCount .founded {
    font-family: Dosis, sans-serif;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 1.5px;
    line-height: 25px;
    text-transform: uppercase; }
  .dayCount .bg-line:first-of-type {
    margin-bottom: 8px; }
  .dayCount .bg-line:last-of-type {
    margin-top: 22px; }

#principles .bg-line.bottom {
  height: 60px; }

#ourStory {
  background-image: url("/images/GridTile.png");
  background-size: 196px;
  border-bottom: 1px solid #DDDDDD; }
  #ourStory .bg-line.top {
    height: 90px; }
  #ourStory .timeline {
    margin-top: 60px; }
    #ourStory .timeline .top-o {
      margin-top: 30px;
      height: auto; }
  #ourStory ul.timeline-nodes {
    position: relative;
    width: 1330px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
    list-style: none;
    padding: 0; }
    #ourStory ul.timeline-nodes .image-group {
      display: none; }
    #ourStory ul.timeline-nodes .text-node {
      padding: 15px; }
  @media print, screen and (min-width: 40em) {
    #ourStory ul.timeline-nodes {
      height: 4070px; }
      #ourStory ul.timeline-nodes > li {
        position: absolute; }
      #ourStory ul.timeline-nodes .bg-line {
        left: 0;
        right: 0; }
        #ourStory ul.timeline-nodes .bg-line.tl-topline {
          top: 0;
          height: 2054px; }
        #ourStory ul.timeline-nodes .bg-line.tl-bottomline {
          height: 1704px;
          top: 2410px; }
      #ourStory ul.timeline-nodes .rellax {
        position: relative; }
      #ourStory ul.timeline-nodes .image-group {
        transform-origin: left top;
        display: block; }
        #ourStory ul.timeline-nodes .image-group.right-side {
          transform-origin: right top; }
        #ourStory ul.timeline-nodes .image-group > ul {
          list-style: none;
          margin: 0; }
          #ourStory ul.timeline-nodes .image-group > ul > li {
            position: absolute; }
      #ourStory ul.timeline-nodes .text-node {
        width: 504px;
        max-width: 50%; } }
    @media print, screen and (min-width: 40em) and (min-width: 40em) {
      #ourStory ul.timeline-nodes .text-node.right-side {
        left: 50%;
        text-align: right; } }
  @media print, screen and (min-width: 40em) {
        #ourStory ul.timeline-nodes .text-node .awardsList {
          padding-top: 20px;
          margin-left: -15px;
          position: absolute;
          width: 630px; }
          #ourStory ul.timeline-nodes .text-node .awardsList .award.blue-ribbon {
            padding-bottom: 32px; }
      #ourStory ul.timeline-nodes .tl01 {
        top: 0;
        width: 407px;
        height: 234px; }
        #ourStory ul.timeline-nodes .tl01 .tl01_2 {
          bottom: 0;
          right: 0; } }
    @media screen and (min-width: 40em) and (max-width: 55.625em) {
      #ourStory ul.timeline-nodes .tl01 {
        top: 60px; } }
  @media print, screen and (min-width: 40em) {
      #ourStory ul.timeline-nodes .tl02 {
        top: 142px;
        width: 482px;
        height: 651px; }
        #ourStory ul.timeline-nodes .tl02 .tl02_1 {
          top: 0;
          left: 0; }
        #ourStory ul.timeline-nodes .tl02 .tl02_2 {
          top: 203px;
          right: 0; }
        #ourStory ul.timeline-nodes .tl02 .tl02_3 {
          bottom: 0;
          left: 0; }
      #ourStory ul.timeline-nodes .tl03 {
        top: 291px; }
      #ourStory ul.timeline-nodes .tl04 {
        top: 810px;
        width: 411px;
        height: 534px; }
        #ourStory ul.timeline-nodes .tl04 .tl04_1 {
          top: 0;
          right: 0; }
        #ourStory ul.timeline-nodes .tl04 .tl04_2 {
          top: 266px;
          left: 0; }
        #ourStory ul.timeline-nodes .tl04 .tl04_3 {
          bottom: 0;
          right: 12px; } }
    @media screen and (min-width: 40em) and (max-width: 52.5em) {
      #ourStory ul.timeline-nodes .tl04 {
        top: 880px; } }
    @media screen and (min-width: 40em) and (max-width: 45em) {
      #ourStory ul.timeline-nodes .tl04 {
        top: 960px; } }
  @media print, screen and (min-width: 40em) {
      #ourStory ul.timeline-nodes .tl05 {
        top: 923px; }
      #ourStory ul.timeline-nodes .tl06 {
        top: 1276px;
        width: 543px;
        height: 552px; }
        #ourStory ul.timeline-nodes .tl06 .tl06_1 {
          top: 0;
          left: 0; }
        #ourStory ul.timeline-nodes .tl06 .tl06_2 {
          top: 82px;
          right: 0; }
        #ourStory ul.timeline-nodes .tl06 .tl06_3 {
          top: 221px;
          left: 212px; }
        #ourStory ul.timeline-nodes .tl06 .tl06_4 {
          bottom: 0;
          left: 80px; }
      #ourStory ul.timeline-nodes .tl07 {
        top: 1499px; }
      #ourStory ul.timeline-nodes .tl08 {
        top: 1874px;
        width: 656px;
        height: 683px; }
        #ourStory ul.timeline-nodes .tl08 .tl08_1 {
          top: 0;
          right: 0px; }
        #ourStory ul.timeline-nodes .tl08 .tl08_2 {
          top: 169px;
          left: 210px; }
        #ourStory ul.timeline-nodes .tl08 .tl08_3 {
          top: 334px;
          right: 36px; }
        #ourStory ul.timeline-nodes .tl08 .tl08_4 {
          top: 474px;
          left: 330px;
          display: none; } }
    @media screen and (min-width: 40em) and (min-width: 50em) {
      #ourStory ul.timeline-nodes .tl08 {
        top: 1814px; }
        #ourStory ul.timeline-nodes .tl08 .tl08_4 {
          display: block; } }
    @media screen and (min-width: 40em) and (min-width: 54em) {
      #ourStory ul.timeline-nodes .tl08 {
        top: 1874px; }
        #ourStory ul.timeline-nodes .tl08 .tl08_4 {
          top: 444px;
          left: 250px; } }
    @media screen and (min-width: 40em) and (min-width: 61.25em) {
      #ourStory ul.timeline-nodes .tl08 .tl08_2 {
        top: 219px;
        left: 200px; }
      #ourStory ul.timeline-nodes .tl08 .tl08_3 {
        top: 424px;
        right: 56px; }
      #ourStory ul.timeline-nodes .tl08 .tl08_4 {
        top: 474px;
        left: 150px; } }
  @media print, screen and (min-width: 40em) {
      #ourStory ul.timeline-nodes .tl09 {
        top: 1970px; }
      #ourStory ul.timeline-nodes .tl10 {
        top: 2495px;
        width: 529px;
        height: 578px; }
        #ourStory ul.timeline-nodes .tl10 .tl10_1 {
          left: 30px; }
        #ourStory ul.timeline-nodes .tl10 .tl10_2 {
          left: 141px;
          top: 189px; }
        #ourStory ul.timeline-nodes .tl10 .tl10_3 {
          left: 0;
          top: 420px; }
      #ourStory ul.timeline-nodes .tl11 {
        top: 2720px; }
      #ourStory ul.timeline-nodes .tl12 {
        top: 2991px;
        width: 515px;
        height: 642px; }
        #ourStory ul.timeline-nodes .tl12 .tl12_1 {
          top: 0;
          right: 53px; }
        #ourStory ul.timeline-nodes .tl12 .tl12_2 {
          top: 158px;
          left: 0; }
        #ourStory ul.timeline-nodes .tl12 .tl12_3 {
          top: 360px;
          right: 0; }
      #ourStory ul.timeline-nodes .tl13 {
        top: 3101px; } }
    @media screen and (min-width: 40em) and (min-width: 55em) {
      #ourStory ul.timeline-nodes .tl13 {
        top: 3191px; } }
  @media print, screen and (min-width: 40em) {
      #ourStory ul.timeline-nodes .tl14 {
        top: 3573px;
        width: 476px;
        height: 443px; }
        #ourStory ul.timeline-nodes .tl14 .tl14_1 {
          top: 0;
          left: 0; }
        #ourStory ul.timeline-nodes .tl14 .tl14_2 {
          top: 169px;
          left: 270px; }
      #ourStory ul.timeline-nodes .tl15 {
        top: 3680px; } }
  @media screen and (min-width: 75em) {
    #ourStory ul.timeline-nodes .tl15 {
      top: 3783px; }
    #ourStory ul.timeline-nodes .text-node.right-side {
      padding-left: 35px; }
    #ourStory ul.timeline-nodes .text-node.left-side {
      padding-right: 35px; }
    #ourStory ul.timeline-nodes .text-node .awardsList {
      width: 720px; } }
  @media screen and (max-width: 39.9375em) {
    #ourStory .tl09 {
      padding: 40px 0; }
    #ourStory .award {
      padding-top: 30px; }
      #ourStory .award .award-logo {
        margin-bottom: 24px;
        text-align: center; } }

#ourTeam {
  margin-top: 40px; }
  #ourTeam .profile {
    display: block;
    position: relative;
    background-image: linear-gradient(-225deg, #E0E0E0 0%, #A7A7A7 100%);
    padding: 50% 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
    #ourTeam .profile.justin {
      background-image: url("/images/about/teampics/justin-square.jpg"); }
    #ourTeam .profile.nate {
      background-image: url("/images/about/teampics/nate-square.jpg"); }
    #ourTeam .profile.matthew {
      background-image: url("/images/about/teampics/matthew-square.jpg"); }
    #ourTeam .profile.sunny {
      background-image: url("/images/about/teampics/sunny-square.jpg"); }
    #ourTeam .profile.jamie {
      background-image: url("/images/about/teampics/jamie-square.jpg"); }
    #ourTeam .profile.oliver {
      background-image: url("/images/about/teampics/oliver-square.jpg"); }
    #ourTeam .profile.generic {
      background-image: url("/images/about/teampics/generic-square.jpg"); }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      #ourTeam .profile.justin {
        background-image: url("/images/about/teampics/justin-square@2x.jpg"); }
      #ourTeam .profile.nate {
        background-image: url("/images/about/teampics/nate-square@2x.jpg"); }
      #ourTeam .profile.matthew {
        background-image: url("/images/about/teampics/matthew-square@2x.jpg"); }
      #ourTeam .profile.sunny {
        background-image: url("/images/about/teampics/sunny-square@2x.jpg"); }
      #ourTeam .profile.jamie {
        background-image: url("/images/about/teampics/jamie-square@2x.jpg"); }
      #ourTeam .profile.oliver {
        background-image: url("/images/about/teampics/oliver-square@2x.jpg"); } }
    #ourTeam .profile:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 120px;
      background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.33) 89%);
      z-index: 0; }
    #ourTeam .profile .info {
      position: absolute;
      left: 14px;
      right: 14px;
      bottom: 10px;
      z-index: 1; }
      #ourTeam .profile .info p, #ourTeam .profile .info #map #contact-map-box h4, #map #contact-map-box #ourTeam .profile .info h4 {
        font-family: Dosis, Roboto, sans-serif;
        color: #FFF;
        margin: 0; }
        #ourTeam .profile .info p.name, #ourTeam .profile .info #map #contact-map-box h4.name, #map #contact-map-box #ourTeam .profile .info h4.name {
          font-size: 17px;
          text-transform: uppercase;
          display: block; }
        #ourTeam .profile .info p.title, #ourTeam .profile .info #map #contact-map-box h4.title, #map #contact-map-box #ourTeam .profile .info h4.title {
          font-size: 15px; }
      #ourTeam .profile .info .divider {
        height: 1px;
        width: 45px;
        display: block;
        background-color: rgba(255, 255, 255, 0.6);
        margin: 1px 0; }
  @media screen and (max-width: 23.75em) {
    #ourTeam .small-up-2 > .column, #ourTeam .small-up-2 > .columns, #ourTeam .small-up-2 > .columns {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 8px 16px; } }

.logo-list .logo {
  min-height: 132px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .logo-list .logo a {
    display: block;
    opacity: .5; }
    .logo-list .logo a:hover {
      opacity: 1; }
  .logo-list .logo.collider a img {
    width: 180px; }

.mobile-carousel {
  margin: 35px 0 45px; }
  .mobile-carousel .carousel-cell {
    height: 225px;
    margin: 0 2px;
    display: table; }
    .mobile-carousel .carousel-cell img {
      max-height: 225px;
      max-width: none; }

@media print, screen and (min-width: 40em) {
  .dayCount #dayNumb {
    font-size: 144px; } }

.blueDot {
  width: 42px;
  height: 42px;
  display: block;
  background-color: #25aae1;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out; }

.icon-wrap {
  position: relative; }
  .icon-wrap svg {
    float: left;
    position: relative; }

#iconJumpNav {
  padding: 60px 0 80px;
  border-bottom: 1px solid #DDDDDD;
  position: relative;
  margin-bottom: 10px; }
  #iconJumpNav:after {
    content: '';
    background: url(/images/ui-scrollDown-o.png) center center no-repeat;
    width: 32px;
    height: 32px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%); }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      #iconJumpNav:after {
        background-image: url(/images/ui-scrollDown-o@2x.png);
        background-size: 32px; } }
  #iconJumpNav nav {
    display: flex; }
  #iconJumpNav a {
    flex-grow: 1;
    text-align: center; }
    #iconJumpNav a .icon-align {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center; }
    #iconJumpNav a .title {
      /* STRATEGY &: */
      font-family: Dosis, sans-serif;
      font-size: 16px;
      color: #4D555B;
      letter-spacing: 1.5px;
      line-height: 20px;
      text-transform: uppercase;
      padding-top: 8px; }
    #iconJumpNav a#iconTop-strategy .blueDot {
      bottom: -6px;
      left: -4px; }
    #iconJumpNav a#iconTop-sprints svg {
      margin: -6px 10px 0 5px; }
    #iconJumpNav a#iconTop-sprints .blueDot {
      top: 22px;
      left: 15px; }
    #iconJumpNav a#iconTop-mobileApps .blueDot {
      left: 3px;
      top: -4px; }
    #iconJumpNav a#iconTop-webApps .blueDot {
      right: -7px;
      top: -6px; }
    #iconJumpNav a#iconTop-webDesign .blueDot {
      left: 5px;
      top: -5px; }
    #iconJumpNav a#iconTop-inbound-marketing .blueDot {
      left: -9px;
      top: 20px; }
    #iconJumpNav a#iconTop-cloud .blueDot {
      left: 5px;
      top: 6px; }
    #iconJumpNav a#iconTop-branding .blueDot {
      right: -5px;
      bottom: -6px; }
    #iconJumpNav a#iconTop-accessibility .blueDot {
      top: 23px;
      right: -8px; }
    #iconJumpNav a#iconTop-usability .blueDot {
      top: 9px;
      right: 7px; }
    #iconJumpNav a#iconTop-other .blueDot {
      top: 9px;
      left: 0px; }
    #iconJumpNav a:hover .title {
      color: #25aae1; }
    #iconJumpNav a:hover .blueDot {
      opacity: 1; }
  @media screen and (max-width: 53.4375em) {
    #iconJumpNav {
      display: none; } }

.service-section {
  padding: 70px 0; }
  .service-section .icon-wrap {
    display: table; }
  .service-section .blueDot {
    opacity: 1;
    width: 52px;
    height: 52px; }
  .service-section#webApps {
    padding-bottom: 110px; }
    .service-section#webApps .macbook {
      max-width: 130%;
      position: absolute;
      width: 828px;
      right: 15px;
      top: 0;
      z-index: -1; }
      @media screen and (max-width: 64.375em) {
        .service-section#webApps .macbook {
          top: 50px; } }
      @media screen and (max-width: 61.25em) {
        .service-section#webApps .macbook {
          top: 150px; } }
      @media screen and (max-width: 53.125em) {
        .service-section#webApps .macbook {
          position: relative;
          top: 75;
          max-width: 99%;
          margin-bottom: -100px; } }
      @media screen and (max-width: 39.9375em) {
        .service-section#webApps .macbook {
          top: auto; } }
  .service-section#strategy .blueDot {
    bottom: -9px;
    left: -7px; }
  .service-section#mobileApps .blueDot {
    left: 4px;
    top: -7px; }
  .service-section#sprints .blueDot {
    left: 8px;
    top: 23px; }
  .service-section#webApps .blueDot {
    right: -6px;
    top: -9px; }
  .service-section#webDesign .blueDot {
    left: 3px;
    top: -8px; }
  .service-section#inbound-marketing .blueDot {
    bottom: 2px;
    left: -9px; }
  .service-section #cloud .blueDot {
    left: 4px;
    top: 6px; }
  .service-section #branding .blueDot {
    right: -5px;
    bottom: -6px; }
  .service-section #accessibility .blueDot {
    top: 24px;
    right: -10px; }
  .service-section #usability .blueDot {
    top: 10px;
    right: 8px; }
  .service-section.condensed {
    padding: 0 0 60px 0; }
  .service-section#inbound-marketing .rightmessage-logo {
    max-width: 90%;
    margin: 24px 5%; }
    @media print, screen and (min-width: 40em) {
      .service-section#inbound-marketing .rightmessage-logo {
        float: left;
        margin-top: 150px; } }
  .service-section#inbound-marketing .right-message-brand-shape {
    position: absolute;
    right: 0;
    background: url("/images/services/right-message-branding-shape.svg") bottom left no-repeat;
    max-width: 440px;
    transform: translateY(51px);
    bottom: 0;
    height: 490px;
    background-size: 150px;
    width: 110px; }
    @media print, screen and (min-width: 40em) {
      .service-section#inbound-marketing .right-message-brand-shape {
        height: 1022px;
        background-position: top left;
        background-size: 312px;
        width: 196px;
        transform: translateY(30px);
        top: 0;
        bottom: auto; } }
    @media print, screen and (min-width: 64em) {
      .service-section#inbound-marketing .right-message-brand-shape {
        width: calc(50% - 310px);
        transform: translateY(-20px);
        background-size: 312px; } }
    @media screen and (min-width: 68.75em) {
      .service-section#inbound-marketing .right-message-brand-shape {
        transform: translateY(-100px);
        background-size: 400px; } }
    @media screen and (min-width: 75em) {
      .service-section#inbound-marketing .right-message-brand-shape {
        background-size: 489px 1022px;
        transform: translateY(-190px); } }

.section-icon {
  text-align: center;
  display: flex;
  padding-bottom: 40px;
  justify-content: center; }
  .condensed .section-icon {
    height: 110px;
    padding: 0;
    justify-content: center;
    margin-bottom: 20px; }
    .condensed .section-icon .icon-wrap {
      align-self: flex-end; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+11 specific styles go here */
  #rochesternowfan_hype_container, #rochesternowfan_hype_container .HYPE_scene {
    overflow: visible !important; } }

@media print, screen and (min-width: 40em) {
  .img-design-sprint {
    margin-top: 130px; } }

.cta-band {
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  flex-wrap: wrap; }
  .cta-band .box {
    flex-grow: 1;
    overflow: hidden;
    padding: 0 4px; }
    .cta-band .box:first-child {
      padding-left: 0; }
    .cta-band .box:last-child {
      padding-right: 0; }
    .cta-band .box a {
      display: block;
      min-height: 177px;
      background-color: #4D555B;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative; }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .cta-band .box a {
          /* IE10+11 specific styles go here */
          min-height: 0;
          height: 177px; } }
      .cta-band .box a:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.65);
        -webkit-transition: background-color 0.3s ease-in-out;
        -moz-transition: background-color 0.3s ease-in-out;
        -o-transition: background-color 0.3s ease-in-out;
        transition: background-color 0.3s ease-in-out; }
      .cta-band .box a .text {
        font-size: 24px;
        z-index: 1;
        color: #FFF;
        line-height: 36px;
        text-transform: uppercase;
        padding-right: 60px;
        padding-left: 10px;
        position: relative;
        -webkit-transition: padding 0.4s ease-in-out;
        -moz-transition: padding 0.4s ease-in-out;
        -o-transition: padding 0.4s ease-in-out;
        transition: padding 0.4s ease-in-out; }
        .cta-band .box a .text:after {
          content: '';
          background: url(/images/sprite-main/cta-arrow-o.png) center center no-repeat;
          width: 38px;
          height: 38px;
          position: absolute;
          display: block;
          right: 0;
          top: 50%;
          transform: translateY(-50%); }
          @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            .cta-band .box a .text:after {
              background-image: url(/images/sprite-main/cta-arrow-o@2x.png);
              background-size: 38px; } }
      .cta-band .box a:hover:before {
        background-color: rgba(0, 0, 0, 0.75); }
      .cta-band .box a:hover .text {
        padding-right: 75px; }
      .cta-band .box a.arrow-left .text {
        padding-right: 0;
        padding-left: 60px;
        padding-right: 10px; }
        .cta-band .box a.arrow-left .text:after {
          right: auto;
          left: 0;
          transform: rotate(180deg) translateY(50%); }
      .cta-band .box a.arrow-left:hover .text {
        padding-left: 75px; }
    .cta-band .box.cta-work a, .cta-band .box.cta-viewWork a {
      background-image: url(/images/cta-bg-ipadDraw.jpg); }
    .cta-band .box.cta-services a {
      background-image: url(/images/cta-bg-whiteboard02.jpg); }
    .cta-band .box.cta-getStarted a {
      background-image: url(/images/cta-bg-whiteboard.jpg); }
      body.services .cta-band .box.cta-getStarted a {
        background-image: url(/images/cta-bg-whiteboard02.jpg); }
    .cta-band .box.cta-viewWork a .text {
      padding: 0 60px; }
      .cta-band .box.cta-viewWork a .text:after {
        display: none; }
    body.case-study .cta-band .box {
      flex-basis: 32%; }
  @media screen and (max-width: 39.9375em) {
    .cta-band .box {
      width: 100%;
      clear: both;
      padding: 4px 0px !important; }
      .cta-band .box a {
        min-height: 100px; }
      .cta-band .box:first-child {
        padding-top: 0 !important; }
      .cta-band .box:last-child {
        padding-bottom: 0 !important; }
      body.case-study .cta-band .box {
        flex-basis: 100%; } }

footer {
  padding: 42px 0;
  background-color: #2B3137 !important; }
  footer .fa-heart {
    color: #65727b;
    margin-right: 4px;
    margin-left: 4px; }
  footer .christianFish {
    width: 33px;
    height: 16px;
    display: inline-block;
    background: url(../images/christian-fish.png) 0 bottom no-repeat;
    margin-left: 9px;
    margin-bottom: -2px; }
  footer .socialMedia a {
    color: #747E83;
    margin-right: 6px;
    font-size: 1.8em; }
  footer .socialMedia a:hover {
    color: #849094; }
  footer .font-medium {
    font-size: 1.1em; }
  footer .horizRule {
    border-bottom: 1px solid #4A4A4A;
    margin-bottom: 25px; }
  footer #bored a {
    color: #CACACA;
    margin: 6px 0 30px;
    padding: 4px 10px;
    display: inline-block;
    border-radius: 3px;
    background-color: rgba(38, 43, 49, 0.4); }
    footer #bored a:hover {
      background-color: #1d2126;
      color: #FFF; }
    .mobile-device footer #bored a {
      display: none; }
    @media screen and (max-width: 39.9375em) {
      footer #bored a {
        display: none; } }
  footer .icn-heart {
    background: url(/images/footer-icn-heart@2x.png) no-repeat;
    width: 16px;
    height: 14px;
    background-size: 100%;
    display: inline-block;
    margin: 1px 2px -1px; }
  @media print, screen and (min-width: 40em) {
    footer {
      background: url(/images/owl-eyes-footer@2x.png) right bottom no-repeat/382px; } }

ul#footer-nav {
  display: table;
  margin: 20px auto;
  list-style: none;
  padding: 0; }
  ul#footer-nav li a {
    font-size: 14px;
    padding: 4px 0;
    color: #27AAE1;
    line-height: 30px;
    border-radius: 4px;
    text-transform: uppercase;
    display: block; }
    ul#footer-nav li a.star {
      position: relative;
      padding-left: 20px; }
      ul#footer-nav li a.star:before, ul#footer-nav li a.star:after {
        content: '';
        position: absolute;
        top: 9px;
        left: 15px;
        background: url(/images/sprite-main/footer-star@2x.png) center center no-repeat;
        background-size: 16px;
        width: 16px;
        height: 16px;
        display: block; }
      ul#footer-nav li a.star:after {
        background-image: url(/images/sprite-main/footer-star-hover@2x.png);
        opacity: 0; }
    ul#footer-nav li a:hover, ul#footer-nav li a:focus {
      color: #FFF; }
      ul#footer-nav li a:hover.star:after, ul#footer-nav li a:focus.star:after {
        opacity: 1; }
  @media screen and (min-width: 46.875em) {
    ul#footer-nav li {
      float: left; }
      ul#footer-nav li a {
        float: left;
        padding: 0 18px;
        display: inline-block; }
        ul#footer-nav li a.star {
          padding-left: 38px; }
          ul#footer-nav li a.star:before, ul#footer-nav li a.star:after {
            left: 18px;
            top: 5px; } }

@media screen and (max-width: 39.9375em) {
  background-size: 30% auto; }

body.home .slice.mainImg.imgBg {
  min-height: 460px; }
  body.home .slice.mainImg.imgBg .bg-line.bottom {
    position: absolute; }

body.home .mainImg figure {
  text-align: center; }

.home-intro-text {
  padding: 40px 0; }
  .home-intro-text p, .home-intro-text #map #contact-map-box h4, #map #contact-map-box .home-intro-text h4 {
    font-size: 19px;
    line-height: 35px; }

#coreApproaches {
  width: 1320px;
  max-width: 100%;
  margin: 80px auto 40px; }
  #coreApproaches #diagram-blended-approach {
    position: relative; }
  #coreApproaches svg.bubble {
    position: relative; }
    #coreApproaches svg.bubble .cls-1 {
      fill: #27aae1;
      opacity: 0.74; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    #coreApproaches {
      /* IE10 & IE11 CSS styles go here */ }
      #coreApproaches svg.bubble {
        height: 480px; } }
  #coreApproaches .approach-bubble {
    position: relative; }
  #coreApproaches .circle-icon {
    margin-bottom: 16px; }
  #coreApproaches .bubble-content {
    position: absolute;
    color: #FFF;
    top: 100px;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);
    text-align: center;
    opacity: 0; }
    #coreApproaches .bubble-content h3 {
      margin-bottom: 24px; }
  #coreApproaches .initial-view {
    width: 33.33333%;
    pointer-events: none; }
    #coreApproaches .initial-view .bubble-content {
      top: 75px;
      opacity: 1; }
      #coreApproaches .initial-view .bubble-content h3 {
        margin-bottom: 10px; }
  @media screen and (min-width: 62.5em) {
    #coreApproaches .approach-bubble {
      width: 33.33333%;
      padding: 0 2%; }
      #coreApproaches .approach-bubble svg.bubble {
        width: 124%;
        margin: 0 -12%; }
    #coreApproaches .bubble-content {
      top: 75px; }
    #coreApproaches .flex-bubbles {
      display: flex; } }
  @media screen and (min-width: 68.75em) {
    #coreApproaches .bubble-content {
      top: 100px; } }
  @media screen and (max-width: 62.4375em) {
    #coreApproaches #diagram-blended-approach {
      width: 480px;
      max-width: 100%;
      margin: 0 auto; }
      #coreApproaches #diagram-blended-approach .approach-bubble:not(:first-child) {
        margin-top: -15%; }
      #coreApproaches #diagram-blended-approach .approach-bubble .bubble-content {
        top: 50%;
        transform: translate(-50%, -50%); }
      #coreApproaches #diagram-blended-approach .approach-bubble svg.bubble {
        max-width: 100%; } }
  @media screen and (max-width: 29.375em) {
    #coreApproaches #diagram-blended-approach .initial-view .bubble-content {
      top: 40px; }
      #coreApproaches #diagram-blended-approach .initial-view .bubble-content .circle-icon {
        margin-bottom: 10px; }
      #coreApproaches #diagram-blended-approach .initial-view .bubble-content h3 {
        font-size: 20px;
        margin-bottom: 18px; } }
  @media screen and (max-width: 25.9375em) {
    #coreApproaches #diagram-blended-approach .initial-view .bubble-content {
      top: 30px; } }
  @media screen and (max-width: 23.75em) {
    #coreApproaches #diagram-blended-approach .initial-view .bubble-content {
      top: 20px; } }
  @media screen and (max-width: 21.875em) {
    #coreApproaches #diagram-blended-approach .approach-bubble svg.bubble {
      max-width: 120%;
      margin: 0 -10%; }
    #coreApproaches #diagram-blended-approach .initial-view .bubble-content {
      top: 30px; } }
  #coreApproaches #circle3, #coreApproaches #circle2 {
    visibility: hidden; }
  #coreApproaches #bubble-2 svg.bubble {
    transform: rotate(110deg); }
  #coreApproaches #bubble-3 svg.bubble {
    transform: rotate(-20deg); }

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotating-reverse {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); } }
  #coreApproaches #bubble-1 svg.bubble, #coreApproaches #bubble-3 svg.bubble {
    -webkit-animation: rotating 30s linear infinite;
    -moz-animation: rotating 30s linear infinite;
    -ms-animation: rotating 30s linear infinite;
    -o-animation: rotating 30s linear infinite;
    animation: rotating 30s linear infinite; }
  #coreApproaches #bubble-2 svg.bubble {
    -webkit-animation: rotating-reverse 40s linear infinite;
    -moz-animation: rotating-reverse 40s linear infinite;
    -ms-animation: rotating-reverse 40s linear infinite;
    -o-animation: rotating-reverse 40s linear infinite;
    animation: rotating-reverse 40s linear infinite; }

a#servingBg {
  background: transparent url("/images/bg-mobile-folks-we-serve.jpg") no-repeat;
  background-size: 100%;
  padding: 0 0 33.39844% 0 !important;
  position: relative;
  margin-top: 40px; }
  @media print, screen and (min-width: 40em) {
    a#servingBg {
      margin-top: 80px;
      margin-bottom: 8px;
      background-image: url("/images/bg-folks-we-serve.jpg");
      padding: 0 0 20% 0 !important; } }
  a#servingBg:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(37, 170, 225, 0.92);
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-in-out;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out; }
  a#servingBg .text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: table;
    font-size: 24px;
    z-index: 2;
    color: #FFF;
    line-height: 36px;
    text-transform: uppercase;
    padding-right: 60px;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out; }
    a#servingBg .text:after {
      content: '';
      background: url(/images/sprite-main/cta-arrow-o.png) center center no-repeat;
      position: absolute;
      display: block;
      right: 0;
      top: -2px; }
    @media print, screen and (min-width: 40em) {
      a#servingBg .text {
        font-size: 32px; } }
  a#servingBg:hover:after {
    opacity: 1; }
  a#servingBg:hover .text {
    opacity: 1; }

#blendedApproach .bg-line.top {
  height: 95px; }

#blendedApproach .bg-line.bottom {
  height: 66px; }

@media screen and (max-width: 39.9375em) {
  #blendedApproach {
    padding: 40px 0; }
    #blendedApproach .bg-line.top {
      height: 60px; } }

body.home #ourWork .bg-line.top {
  top: -1px; }

body.home #ourWork .supporting-text {
  width: 730px;
  margin: 24px auto 80px; }

body.home #awards {
  margin-top: 60px; }
  body.home #awards > .bg-line.top-o {
    height: 110px;
    margin-bottom: -40px; }
  body.home #awards .titleEchoArea {
    padding-bottom: 40px; }
  body.home #awards .box.gray-border {
    width: 870px;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px; }
    body.home #awards .box.gray-border .bg-line.top {
      top: -1px; }
    body.home #awards .box.gray-border .titleEchoArea {
      margin-bottom: 60px; }
  body.home #awards .titleEchoArea {
    margin-bottom: 0 !important; }
  body.home #awards .award {
    padding-top: 30px; }
    body.home #awards .award .award-logo {
      margin-bottom: 24px;
      text-align: center; }

body.home #fineFolks .bg-line.top {
  height: 80px; }

#introText figure {
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative; }
  #introText figure:after {
    content: '';
    background-color: #FFF;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 94px;
    height: 1px; }

#introText svg#intro_anim_svg {
  /*.dream,*/ }
  @media print, screen and (min-width: 40em) {
    #introText svg#intro_anim_svg {
      transform: scale(1.15);
      margin-bottom: 4px; } }
  #introText svg#intro_anim_svg .plan, #introText svg#intro_anim_svg .do, #introText svg#intro_anim_svg .d_d_o_o {
    visibility: hidden; }
  #introText svg#intro_anim_svg #dots .dot {
    opacity: 0; }

#tabTray {
  background-color: #f4f6f7;
  padding-bottom: 20px;
  -webkit-transition: padding-bottom 0.4s ease-in-out 0.8s;
  -moz-transition: padding-bottom 0.4s ease-in-out 0.8s;
  -o-transition: padding-bottom 0.4s ease-in-out 0.8s;
  transition: padding-bottom 0.4s ease-in-out 0.8s; }
  #tabTray.tabs-default {
    -webkit-transition: padding-bottom 0.4s ease-in-out 0.4s;
    -moz-transition: padding-bottom 0.4s ease-in-out 0.4s;
    -o-transition: padding-bottom 0.4s ease-in-out 0.4s;
    transition: padding-bottom 0.4s ease-in-out 0.4s;
    padding-bottom: 40px; }
  @media screen and (max-width: 39.9375em) {
    #tabTray .small-6.column:nth-child(1n), #tabTray .small-6.columns:nth-child(1n) {
      padding-left: 0; }
    #tabTray .small-6.column:nth-child(2n), #tabTray .small-6.columns:nth-child(2n) {
      padding-right: 0; } }

#tabPanel {
  background: #FFF;
  border-top: 1px solid #E7EAEB;
  overflow: hidden;
  display: none;
  -webkit-transition: border-top 0.4s ease-in-out;
  -moz-transition: border-top 0.4s ease-in-out;
  -o-transition: border-top 0.4s ease-in-out;
  transition: border-top 0.4s ease-in-out; }
  .tabs-default #tabPanel {
    border-top: 1px solid #F1F4F5; }
  #tabPanel .titleEchoArea {
    z-index: 0; }
  #tabPanel .tab-content {
    opacity: 0;
    display: none; }

.tab {
  text-align: center;
  z-index: 1;
  background-color: #FFF;
  padding: 20px 14px 50px;
  position: relative;
  margin-bottom: -1px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  .tab .tab-explanation {
    width: 400px;
    margin: 0 auto;
    max-width: 100%; }
  .tab .button-spacing {
    height: 70px;
    display: block; }
  .tab a.button {
    position: absolute; }
  @media print, screen and (min-width: 40em) {
    .tab {
      padding: 40px; }
      .tab a.button {
        left: 50px;
        right: 50px;
        bottom: 40px; } }
  @media print, screen and (min-width: 64em) {
    .tab a.button {
      left: 105px;
      right: 105px; } }
  @media screen and (min-width: 75em) {
    .tab a.button {
      left: 160px;
      right: 160px; } }
  @media screen and (max-width: 39.9375em) {
    .tab .tab-explanation {
      display: none; } }
  .tab.inactive, .tab.active {
    height: auto !important;
    padding-bottom: 60px; }
  .tab.inactive {
    background-color: transparent; }
    .tab.inactive:hover {
      background-color: rgba(255, 255, 255, 0.4);
      -webkit-transition: background 0.2s ease-in-out;
      -moz-transition: background 0.2s ease-in-out;
      -o-transition: background 0.2s ease-in-out;
      transition: background 0.2s ease-in-out; }
  .tab h3 {
    line-height: 48px; }
    .tab h3:after {
      display: none; }
    .tab h3 .bar {
      height: 1px;
      width: 94px;
      background-color: #25aae1;
      position: absolute;
      left: 50%;
      bottom: -4px;
      transform: translateX(-50%); }
    @media screen and (max-width: 39.9375em) {
      .tab h3 {
        max-width: 220px;
        display: table;
        margin: 0 auto 20px;
        line-height: 26px; }
        .tab h3 .bar {
          bottom: -8px; } }
  .tab .button {
    margin: 20px auto 0; }
  .tab .openClose {
    opacity: 1;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    cursor: pointer;
    pointer-events: none;
    text-align: center; }
    .tab .openClose svg {
      width: 28px;
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out; }
      .tab .openClose svg .line, .tab .openClose svg .circle {
        stroke: #25aae1;
        display: block; }
      .tab .openClose svg:hover .line, .tab .openClose svg:hover .circle {
        stroke: #4D555B; }
    @media print, screen and (min-width: 40em) {
      .tab .openClose {
        pointer-events: all;
        opacity: 0;
        bottom: 20px; }
        .tab .openClose svg {
          transform: rotate(-45deg); } }
    .tabs-default .tab .openClose {
      pointer-events: none; }
  .tab.inactive {
    cursor: pointer; }
    .tab.inactive .openClose svg {
      transform: rotate(0deg); }
      .tab.inactive .openClose svg .line, .tab.inactive .openClose svg .circle {
        stroke: #4D555B; }
  .tab.active .openClose svg {
    transform: rotate(-45deg); }

.form-box .group {
  position: relative; }
  .form-box .group label {
    left: 15px;
    right: 15px;
    top: 14px;
    color: #7d8891;
    font-size: 14px;
    line-height: 1.4rem;
    position: absolute;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .form-box .group .float-label {
    display: block;
    border: none;
    box-shadow: none;
    margin-bottom: 0;
    padding: 0 15px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
    .form-box .group .float-label:focus, .form-box .group .float-label.filled {
      outline: none; }
  .form-box .group textarea ~ label {
    top: -30px !important; }
  .form-box .group input.float-label {
    height: 51px;
    max-height: 51px; }
    .form-box .group input.float-label:focus, .form-box .group input.float-label.filled {
      padding-top: 15px; }
  .form-box .group input, .form-box .group textarea {
    line-height: 1.4em; }
    .form-box .group input::-webkit-input-placeholder, .form-box .group textarea::-webkit-input-placeholder {
      color: #25aae1;
      font-size: 14px;
      padding-top: 6px; }
    .form-box .group input::-moz-placeholder, .form-box .group textarea::-moz-placeholder {
      color: #25aae1;
      font-size: 14px;
      padding-top: 6px; }
    .form-box .group input:-ms-input-placeholder, .form-box .group textarea:-ms-input-placeholder {
      color: #25aae1;
      font-size: 14px;
      padding-top: 6px; }
    .form-box .group input:-moz-placeholder, .form-box .group textarea:-moz-placeholder {
      color: #25aae1;
      font-size: 14px;
      padding-top: 6px; }
  .form-box .group textarea {
    margin-top: 35px !important;
    resize: none;
    min-height: 44.8px !important; }
    .form-box .group textarea.rows-3 {
      min-height: 72px !important; }
    .form-box .group textarea.rows-4 {
      min-height: 96px !important; }
    .form-box .group textarea.rows-5 {
      min-height: 120px !important; }
    .form-box .group textarea.rows-6 {
      min-height: 144px !important; }
    .form-box .group textarea.rows-7 {
      min-height: 168px !important; }
  .form-box .group .float-label:focus ~ label, .form-box .group label.input-filled {
    top: 1px;
    font-size: 13px;
    color: #056E9A; }
  .form-box .group .bar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }
    .form-box .group .bar:before, .form-box .group .bar:after {
      content: '';
      height: 2px;
      width: 0;
      bottom: 0;
      position: absolute;
      background: #25aae1;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all; }
    .form-box .group .bar:before {
      left: 50%; }
    .form-box .group .bar:after {
      right: 50%; }
  .form-box .group .float-label:focus ~ .bar:before, .form-box .group .float-label:focus ~ .bar:after {
    width: 50%; }

@media print, screen and (min-width: 40em) {
  .form-box > .row:not(:last-of-type) {
    border-bottom: 1px solid #CACACA; }
  .form-box .border-left {
    border-left: 1px solid #CACACA; }
  .form-box .group label {
    font-size: 16px; } }

@media screen and (max-width: 39.9375em) {
  .form-box textarea {
    min-height: 70px; }
  .form-box > .row:not(:last-of-type) > .column, .form-box > .row:not(:last-of-type) > .columns, .form-box > .row:not(:last-of-type) > .columns {
    border-bottom: 1px solid #CACACA; } }

#budget-scale-error-message {
  position: relative; }
  #budget-scale-error-message ul.parsley-errors-list {
    display: table;
    top: 6px;
    left: 0;
    right: auto;
    border-radius: 4px 4px 0 0; }

form h3 {
  text-align: center;
  display: block;
  width: 100%; }
  form h3:not(.no-rule) {
    margin-bottom: 45px; }
  form h3 .number-dot {
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 36px;
    color: #FFF;
    clear: both;
    display: block;
    margin: 0 auto 7px;
    width: 73px;
    line-height: 73px;
    border-radius: 50%;
    background-color: #25aae1;
    padding-left: 3px; }

form .warning.callout {
  border: 1px solid #eac981;
  position: relative; }
  form .warning.callout.has-prev-data {
    display: none; }
  form .warning.callout .orange {
    color: #CA8901;
    margin-right: 20px; }
  form .warning.callout .v-bar {
    color: rgba(202, 137, 1, 0.6);
    margin: 0 8px; }
  form .warning.callout .close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer; }
    form .warning.callout .close:after {
      content: '';
      background: url(/images/sprite-main/close-warning.png) center center no-repeat;
      position: relative;
      top: 5px;
      left: 5px;
      display: block; }

#helpSelect {
  padding-bottom: 15px; }
  #helpSelect label {
    text-align: center;
    color: #9B9B9B;
    font-size: 16px;
    line-height: 20px;
    min-height: 130px;
    padding-top: 15px;
    position: relative;
    border-radius: 4px;
    cursor: pointer; }
    #helpSelect label:after {
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 20; }
    #helpSelect label:hover {
      background-color: #f4f6f7; }
    #helpSelect label .blueDot {
      width: 32px;
      height: 32px; }
    #helpSelect label.checked .blueDot {
      opacity: 1; }
  #helpSelect input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0; }
  #helpSelect .image {
    height: 60px;
    display: table;
    position: relative;
    margin: 0 auto; }
    #helpSelect .image img {
      position: relative;
      z-index: 2; }
  #helpSelect .parsley-errors-list {
    z-index: 100;
    top: -11px;
    border-radius: 4px 0 0 4px; }

#label-website img {
  margin-top: 3px; }

#label-website .blueDot {
  left: 3px; }

#label-app img {
  margin-top: 2px; }

#label-app .blueDot {
  left: 3px; }

#label-branding img {
  margin-top: 2px; }

#label-branding .blueDot {
  right: -1px;
  top: 21px; }

#label-marketing .blueDot {
  top: 13px;
  left: -8px; }

#label-prototyping img {
  margin-top: 1px; }

#label-prototyping .blueDot {
  left: 0px;
  top: 19px; }

#label-cloud .blueDot {
  left: 4px;
  top: 4px; }

#label-accessibility img {
  margin-top: -5px; }

#label-accessibility .blueDot {
  left: 8px;
  top: 12px; }

#label-usability .blueDot {
  left: 9px;
  top: 4px; }

#label-other .blueDot {
  left: 0px;
  top: 11px; }

#budget-radio-select {
  height: 77px;
  position: relative;
  margin: 30px 0 10px;
  border: 1px solid #CACACA;
  overflow: visible;
  display: none; }
  #budget-radio-select ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    position: absolute;
    left: 0;
    width: 1068px; }
    #budget-radio-select ul li {
      margin: 0;
      padding: 0;
      position: relative;
      width: 8.33333%;
      text-align: center; }
      #budget-radio-select ul li:not(:last-child) {
        border-right: 1px solid #CACACA; }
      #budget-radio-select ul li input {
        position: absolute;
        top: 0;
        left: 0; }
    #budget-radio-select ul li, #budget-radio-select ul li label {
      color: #9B9B9B;
      font-size: 18px;
      line-height: 25px;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media screen and (max-width: 63.9375em) {
        #budget-radio-select ul li, #budget-radio-select ul li label {
          font-size: 16px; } }
    #budget-radio-select ul li > label {
      width: 100%;
      cursor: pointer;
      position: relative; }
      #budget-radio-select ul li > label:hover:after {
        /* Placed in absolut to go above guage as well */
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.07);
        z-index: 2; }
    #budget-radio-select ul.budget-guage {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0; }
      #budget-radio-select ul.budget-guage li {
        color: #FFF;
        pointer-events: none; }
  #budget-radio-select .shutter {
    overflow: hidden;
    height: 77px;
    width: 100%;
    max-width: 0;
    -webkit-transition: max-width 1s ease-in-out;
    -moz-transition: max-width 1s ease-in-out;
    -o-transition: max-width 1s ease-in-out;
    transition: max-width 1s ease-in-out;
    position: relative;
    pointer-events: none; }
    #budget-radio-select .shutter .gradient {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 2px;
      background-image: linear-gradient(90deg, #056E9A 1%, #27AAE1 100%); }

#proj-budget-select {
  margin: 1rem 0; }

@media print, screen and (min-width: 40em) {
  #budget-radio-select {
    display: block; }
  #proj-budget-select {
    display: none; } }

.form-callout {
  padding: 64px 0;
  border: 1px solid #CACACA;
  margin-top: 75px;
  position: relative; }
  .form-callout:before {
    content: '';
    position: absolute;
    height: 75px;
    width: 1px;
    left: 50%;
    top: -76px;
    background-color: #CACACA; }

#budgetSummary {
  margin: 30px auto 0;
  font-size: 20px;
  line-height: 30px;
  width: 645px;
  max-width: 100%;
  padding: 12px;
  padding-bottom: 0;
  background-color: transparent;
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out; }
  #budgetSummary strong {
    color: #000000; }
  #budgetSummary:not(.no-bg):hover {
    cursor: pointer;
    background-color: rgba(5, 110, 154, 0.2); }
  #budgetSummary .changing-text {
    min-width: 0;
    opacity: 0;
    overflow: visible;
    display: inline-block;
    position: relative;
    height: 23px;
    -webkit-transition: min-width 0.4s ease-in-out;
    -moz-transition: min-width 0.4s ease-in-out;
    -o-transition: min-width 0.4s ease-in-out;
    transition: min-width 0.4s ease-in-out; }
    #budgetSummary .changing-text .anchor {
      position: absolute;
      left: 0;
      top: 0;
      width: 180px; }
    #budgetSummary .changing-text .budget-figure-setup {
      opacity: 1;
      -webkit-transition: opacity 0.3s ease-in-out;
      -moz-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out; }
    #budgetSummary .changing-text.slide-out {
      min-width: 180px; }
    @media screen and (max-width: 39.9375em) {
      #budgetSummary .changing-text {
        display: block;
        clear: both;
        text-align: center; }
        #budgetSummary .changing-text .anchor {
          left: 50%;
          transform: translateX(-50%); } }
    #budgetSummary .changing-text #proj-budget-edit, #budgetSummary .changing-text #proj-budget-final {
      opacity: 0;
      position: absolute;
      left: 2px;
      right: 0;
      top: -4px; }
    #budgetSummary .changing-text.custom-estimate #proj-budget-edit {
      opacity: 1; }
    #budgetSummary .changing-text.custom-estimate .budget-figure, #budgetSummary .changing-text.custom-estimate .budget-figure-setup {
      opacity: 0; }
  #budgetSummary #budgetFigure {
    min-width: 121px;
    display: inline-block;
    text-align: left;
    padding-left: 6px; }

#projectRequestForm .morph-button {
  margin: 12px auto 50px; }

#map {
  min-height: 355px;
  background: #25aae1 url(/images/map.png) no-repeat center center/cover; }
  @media print, screen and (min-width: 40em) {
    #map {
      background: #25aae1 url(/images/map-med.png) no-repeat center center/cover; } }
  @media screen and (min-width: 40em) and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    #map {
      background: #25aae1 url(/images/map-med@2x.png) no-repeat center center/cover; } }
  @media screen and (min-width: 90.0625em) {
    #map {
      background: #25aae1 url(/images/map-xlarge.png) no-repeat center center/cover; } }
  @media screen and (min-width: 137.5em) {
    #map {
      background: #25aae1 url(/images/map-xlarge@2x.png) no-repeat center center/cover; } }
  #map #contact-map-box {
    background: rgba(242, 242, 242, 0.9);
    border-radius: 2px;
    width: 482px;
    max-width: 100%;
    bottom: 154px;
    /* 14px is carrot height */ }
    #map #contact-map-box .map-box-content {
      padding: 15px 0 5px;
      text-align: center; }
      @media print, screen and (min-width: 40em) {
        #map #contact-map-box .map-box-content .columns:nth-child(2n) {
          padding-left: 0; } }
    #map #contact-map-box h4 {
      color: #000000;
      text-transform: uppercase;
      margin-bottom: 0; }
    #map #contact-map-box:after {
      content: '';
      position: absolute;
      background: url(/images/sprite-main/trnsp-box-carrot-bottom.png) center center no-repeat;
      width: 33px;
      height: 14px;
      left: 50%;
      bottom: -14px;
      transform: translateX(-50%); }
  #map #contact-map-box, #map #pulse-dot, #map #map-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  #map #pulse-dot {
    bottom: 120px;
    bottom: 144px;
    transform: translateX(-8px);
    background: #056E9A; }
    #map #pulse-dot .dot {
      width: 14px;
      height: 14px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      background-color: #056E9A;
      z-index: 10;
      position: absolute; }
    #map #pulse-dot .pulse {
      border: 8px solid #056E9A;
      background: transparent;
      border-radius: 60px;
      height: 50px;
      width: 50px;
      -webkit-animation: pulse 3s ease-out;
      -moz-animation: pulse 3s ease-out;
      animation: pulse 3s ease-out;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      position: absolute;
      top: -18px;
      left: -18px;
      z-index: 1;
      opacity: 0; }
  #map #map-button {
    background: #056E9A;
    bottom: 25px;
    line-height: 20px;
    padding: 18px 18px 16px; }
    #map #map-button:hover {
      background: #034b69; }

.button.send:before {
  content: '';
  background: url(/images/sprite-main/paper-airplane@2x.png) center center no-repeat;
  width: 30px;
  height: 22px;
  background-size: 100%;
  display: inline-block;
  margin: 0 3px -8px -9px; }

.button.send.spinner {
  pointer-events: none; }
  .button.send.spinner:before {
    background: url(/images/spinner-on-blue.gif) no-repeat top left;
    width: 30px;
    height: 22px;
    background-size: 22px; }

form ul.parsley-errors-list {
  position: absolute;
  right: -1px;
  top: -1px;
  background-color: rgba(255, 0, 0, 0.2);
  margin: 0;
  list-style: none;
  font-size: 13px;
  color: red;
  border-radius: 0 0 0 3px; }
  form ul.parsley-errors-list li {
    padding: 2px 6px; }

form input.parsley-error, form textarea.parsley-error {
  background-color: #fff0f0; }

form textarea + .parsley-errors-list {
  top: 0; }

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0.0; }
  25% {
    transform: scale(0);
    opacity: 0.1; }
  50% {
    transform: scale(0.1);
    opacity: 0.3; }
  75% {
    transform: scale(0.5);
    opacity: 0.5; }
  100% {
    transform: scale(1);
    opacity: 0.0; } }

/* Morph Button: Default Styles */
.morph-button {
  position: relative;
  display: block;
  margin: 0 auto; }

.morph-button > button {
  position: relative;
  border: none;
  background-color: #e85657;
  overflow: hidden; }

.morph-button.open > button {
  pointer-events: none; }

.morph-content {
  pointer-events: none; }
  .morph-content .button.single-button {
    margin: 16px auto;
    max-width: 180px; }

.morph-button.open .morph-content {
  pointer-events: auto; }

/* Common styles for overlay and modal type (fixed morph) */
.morph-button-fixed,
.morph-button-fixed .morph-content {
  width: 152px;
  height: 50px; }
  #projectRequestForm .morph-button-fixed, #projectRequestForm
  .morph-button-fixed .morph-content {
    width: 170px; }

.morph-button-fixed > button {
  z-index: 1000;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.1s 0.5s;
  transition: opacity 0.1s 0.5s; }

.morph-button-fixed.open > button {
  opacity: 0;
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s; }

.morph-button-fixed .morph-content {
  position: fixed;
  z-index: 900;
  opacity: 0;
  -webkit-transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
  transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s; }

.morph-button-fixed.open .morph-content {
  opacity: 1; }

.morph-button-fixed .morph-content > div {
  visibility: hidden;
  height: 0;
  opacity: 0;
  padding: 20px;
  -webkit-transition: opacity 0.1s, visibility 0s 0.1s, height 0s 0.1s;
  transition: opacity 0.1s, visibility 0s 0.1s, height 0s 0.1s;
  color: #FFF; }

.morph-button-fixed.open .morph-content > div {
  visibility: visible;
  height: auto;
  opacity: 1;
  -webkit-transition: opacity 0.3s 0.5s;
  transition: opacity 0.3s 0.5s; }

.morph-button-fixed.active > button {
  z-index: 2000; }

.morph-button-fixed.active .morph-content {
  z-index: 1900; }

/* Transitions for overlay button and sidebar button */
.morph-button-overlay .morph-content,
.morph-button-sidebar .morph-content {
  -webkit-transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
  transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s; }

.morph-button-overlay.open .morph-content,
.morph-button-sidebar.open .morph-content {
  -webkit-transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
  transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s; }

/* Morph Button Style: Overlay */
.morph-button.morph-button-overlay {
  margin: 50px auto; }

.morph-button-overlay .morph-content {
  overflow: hidden;
  background: #e85657; }

.morph-button-overlay.open .morph-content {
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%; }

/* Morph Button Style: Modal */
.morph-button-modal::before {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 800;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  pointer-events: none; }

.morph-button-modal.open::before {
  opacity: 1;
  pointer-events: auto; }

.morph-button-modal.active::before {
  z-index: 1800; }

.morph-button-modal .morph-content {
  overflow: hidden;
  -webkit-transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
  transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s; }

.morph-button-modal.open .morph-content {
  top: 50% !important;
  left: 50% !important;
  margin: -210px 0 0 -300px;
  width: 600px;
  height: 340px;
  -webkit-transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
  transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s; }
  #projectRequestForm .morph-button-modal.open .morph-content {
    width: 600px; }
  .morph-button-modal.open .morph-content .icon-close {
    background: url(/images/sprite-main/close-btn-white.png) center center no-repeat;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer; }

.morph-button-modal #check-circle {
  margin-top: 16px; }

.morph-button-modal h2 {
  font-size: 24px;
  color: #FFF;
  margin-top: 24px; }

.morph-button-modal h3 {
  font-size: 20px; }

.morph-button-modal p, .morph-button-modal #map #contact-map-box h4, #map #contact-map-box .morph-button-modal h4 {
  font-size: 18px; }

.morph-button-modal a.button {
  background: #056E9A;
  margin-top: 16px; }

/* Colors and sizes for individual modals */
.morph-button-modal-1 > button,
.morph-button-modal-1 .morph-content {
  background-color: #25aae1; }

.morph-button-modal-1::before {
  background: rgba(241, 241, 241, 0.85); }

/* Let's add some nice easing for all cases */
.morph-button .morph-content,
.morph-button.open .morph-content,
.morph-button-modal-4 .morph-clone {
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1); }

/* Helper classes */
.noscroll {
  overflow: hidden; }

.morph-button-overlay.scroll .morph-content {
  overflow-y: scroll; }

.morph-button-sidebar.scroll .morph-content {
  overflow: auto; }

/* Media Queries */
@media screen and (max-width: 600px) {
  .morph-button-modal.open .morph-content {
    top: 53px !important;
    left: 0% !important;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
    transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s; } }

@media screen and (max-width: 400px) {
  .morph-button-fixed,
  .morph-button-fixed .morph-content {
    width: 200px;
    height: 80px; }
  .morph-button-fixed > button {
    font-size: 75%; }
  .morph-button-sidebar > button {
    font-size: 1.6em; }
  .morph-button-inflow .morph-content .morph-clone {
    font-size: 0.9em; }
  .morph-button-modal-4,
  .morph-button-modal-4 .morph-content {
    width: 220px;
    height: 120px; }
  .morph-button-modal-4 > button {
    font-size: 100%;
    line-height: 50px; }
  .morph-button-modal-4 > button span {
    display: block; }
  .morph-button-modal-4 .morph-clone {
    right: 83px;
    bottom: 26px; }
  .morph-button-sidebar,
  .morph-button-sidebar .morph-content {
    width: 100% !important;
    height: 60px !important; }
  .morph-button-sidebar {
    bottom: 0px;
    left: 0px; }
  .morph-button-sidebar.open .morph-content {
    height: 100% !important; } }

.non-blaster {
  display: none; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10 & IE11 CSS styles go here */
  .blaster-ready {
    display: none; }
  .non-blaster {
    display: block; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE Edge 12+ CSS styles go here */
  .blaster-ready {
    display: none; }
  .non-blaster {
    display: block; } }

body.labs #chamberforce {
  background: url("/images/labs/bg-chamberforce.jpg") no-repeat center center/cover;
  position: relative; }
  body.labs #chamberforce:before {
    content: '';
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background: rgba(8, 37, 50, 0.9); }
  body.labs #chamberforce .hero-position {
    top: 72px;
    left: -20px;
    margin-bottom: -10px; }
    body.labs #chamberforce .hero-position img {
      max-width: 540px; }

body.labs #pixelPress {
  background: url("/images/labs/bg-pixelpress-mobile.jpg") no-repeat center center/cover; }
  @media print, screen and (min-width: 40em) {
    body.labs #pixelPress {
      background: url("/images/labs/bg-pixelpress.jpg") no-repeat center center/cover; } }
  body.labs #pixelPress h2 {
    margin: -4px 0 24px -4px; }
  body.labs #pixelPress .hero-position {
    margin-top: 10px; }

body.labs #rochesterNow {
  background-color: #25aae1; }

body.labs #hiveSync {
  background: url("/images/labs/bg-hivesync-mobile.png") no-repeat center center/cover; }
  @media print, screen and (min-width: 40em) {
    body.labs #hiveSync {
      background: url("/images/labs/bg-hivesync.png") no-repeat center center/cover;
      background: url("/images/labs/bg-hivesync@2x.png") no-repeat center center/cover; } }

body.labs #moreLabs {
  background: #F2F6F8 url("/images/labs/bubbles.svg") no-repeat center center;
  overflow: visible; }
  body.labs #moreLabs .water-top {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: url("/images/labs/water-top.svg") no-repeat top left;
    padding-bottom: 3.6%;
    transform: translateY(-100%);
    display: none; }
  @media screen and (min-width: 75em) {
    body.labs #moreLabs .heroImg {
      padding-left: 50px; } }

body.labs .heroImg {
  position: relative; }
  body.labs .heroImg .hero-position {
    text-align: center;
    margin-bottom: -20%; }
    body.labs .heroImg .hero-position img {
      box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.61); }
    @media print, screen and (min-width: 40em) {
      body.labs .heroImg .hero-position {
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        text-align: right; } }
  body.labs .heroImg.no-shadow img {
    box-shadow: none; }

.lab-item {
  overflow: hidden; }
  .lab-item:not(:last-child) {
    margin-bottom: 8px; }
  .lab-item p, .lab-item #map #contact-map-box h4, #map #contact-map-box .lab-item h4 {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 12px; }
  .lab-item h2 {
    margin-bottom: 35px; }
  .lab-item h3 {
    line-height: 31px;
    padding: 6px 0 8px;
    margin-bottom: 21px; }
